import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import { DAYS_FULL, DAYS_SHORT } from "../constants";
import { HourlyWeekData } from "~/api/v1/ReportData";

type Props = {
  data: HourlyWeekData;
  openingHour?: number;
  closingHour?: number;
};

export type HourlyWeekGrid = {
  x: string[];
  y: string[];
  z: number[][];
  maxZ: number;
};
export default function useHourlyWeekDataGrid({
  data,
  openingHour,
  closingHour,
}: Props): HourlyWeekGrid {
  if (openingHour === undefined) openingHour = 0;
  if (closingHour === undefined) closingHour = 23;

  const { t } = useTranslation();
  const openHours = Array.from(
    {
      length: closingHour - openingHour + 1,
    },
    (_, i) => i + openingHour,
  );
  const x = DAYS_SHORT.map((day) => t(`dateValues.days.short.${day}`));
  const y = openHours.map((hour) => hour.toString() + ":00");

  const { z, maxZ } = useMemo(() => {
    const z: number[][] = Array.from(openHours, () =>
      Array.from({ length: 7 }, () => 0),
    );
    if (!data)
      return {
        z,
        maxZ: 0,
      };

    const maxZ = Object.values(data)
      .map((subdata) =>
        Object.values(subdata).reduce((a, b) => (a > b ? a : b), 0),
      )
      .reduce((a, b) => (a > b ? a : b), 0);

    DAYS_FULL.forEach((dayname, dayindex) => {
      const day: Record<string, number> = data[dayname];

      if (!day) return;

      Object.entries(day).forEach(([hourStr, value]) => {
        const hour = +hourStr;
        if (hour < openingHour || hour > closingHour) return;
        z[hour - openingHour][dayindex] = value;
      });
    });

    return { z, maxZ };
  }, [data]);

  return {
    x,
    y,
    z,
    maxZ,
  };
}
