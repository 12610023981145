import React from "react";
import StyledColumnWrapper from "./Cell.styles";
import { BreakpointObjectGrid } from "./Grid.styles";

const Cell: React.FC<{
  children: React.ReactNode;
  className?: string;
  columnSpan?: number | BreakpointObjectGrid;
}> = ({ children, className, columnSpan = 1 }) => {
  return (
    <StyledColumnWrapper columnSpan={columnSpan} className={className}>
      {children}
    </StyledColumnWrapper>
  );
};

export default Cell;
