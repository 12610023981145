import { IconName } from "./components/atoms/Icon/Icon";

//cookies auth token
export const AUTH_TOKEN = "auth-token";
export const REFRESH_TOKEN = "refresh-token";
export const ORGANIZATION_ID = "organization-id";
export const IMAGE_INFO = "image-info";
export const REFRESH_PERIOD =
  (Number(import.meta.env.VITE_JWT_REFRESH_PERIOD) || 5) * 60;
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
export const WEBSOCKET_SOCIAL = import.meta.env.VITE_WEBSOCKET_SOCIAL;
export const ASPNETCORE_ROLE =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

type NavigationItem = {
  icon: IconName;
  link: string;
  admin_only?: boolean;
  superadmin_only?: boolean;
};

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    icon: "building",
    link: "/locations",
  },
  {
    icon: "park7",
    link: "/area",
  },
  {
    icon: "devices",
    link: "/devices",
  },
  {
    icon: "briefcase",
    link: "/account",
    admin_only: true,
  },
  {
    icon: "user",
    link: "/user-account",
  },
  // {
  //   icon: 'settings',
  //   link: '',
  // },
];

//See Space types in API docs
export const LOCATION_TYPES = [
  {
    key: 0,
    value: "None",
  },
  {
    key: 1,
    value: "Building",
  },
  {
    key: 2,
    value: "Floor",
  },
  {
    key: 3,
    value: "Room",
  },
] as const;

//See Device types in API docs
export const DEVICE_TYPES = [
  {
    key: 0,
    value: "None",
  },
  {
    key: 1,
    value: "PeopleTrackingSensor",
  },
] as const;

//Days
export const DAYS_SHORT = [
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "sun",
] as const;
export const DAYS_FULL = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;
//Occupation state
export enum OccupationState {
  Ok = "ok",
  Warning = "warning",
  Danger = "danger",
}
export const OCCUPATION_STATES = [
  OccupationState.Ok,
  OccupationState.Warning,
  OccupationState.Danger,
] as const;
export const OCCUPATION_STATE_COLORS = ["green", "yellow", "red"] as const;
export const OCCUPATION_STATE_COLOR_DEFAULT = "blue";
//User roles
export enum UserRole {
  PublicScreen = "PublicScreen",
  User = "User",
  Admin = "Admin",
  SuperAdmin = "SuperAdmin",
}
