import React from "react";
import { Link } from "react-router-dom";
import StyledButton from "./Button.styles.js";

type ButtonProps = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  url?: string;
  urlTarget?: string;
  isSubmitting?: boolean;
  dangerButton?: boolean;
  submitButton?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  url,
  urlTarget,
  isSubmitting,
  submitButton = false,
  dangerButton = false,
}: ButtonProps) => {
  if (url) {
    return (
      <Link to={url} target={urlTarget}>
        <StyledButton isSubmitting={isSubmitting} dangerButton={dangerButton}>
          <button
            type="button"
            onClick={() => {
              isSubmitting = true;
            }}
          >
            {label}
          </button>
        </StyledButton>
      </Link>
    );
  }

  return (
    <StyledButton isSubmitting={isSubmitting} dangerButton={dangerButton}>
      <button type={submitButton ? "submit" : "button"} onClick={onClick}>
        {label}
        <div className="submitIcon">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </StyledButton>
  );
};

export default Button;
