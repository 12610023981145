import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  OccupationState,
  OCCUPATION_STATES,
  OCCUPATION_STATE_COLORS,
  OCCUPATION_STATE_COLOR_DEFAULT,
} from "../../../../constants.js";
import { DeviceData } from "../../../../api/v1/Device/device.js";
import { SpaceData } from "~/api/v1/Space";
import { useDevices } from "../../../../hooks/api.js";
import useWebSocketSetter from "../../../../websockets/useWebSocketSetter.js";
import { LocationOccupationData } from "../../../../websockets/websockets.js";
import DistanceBlock from "../../../atoms/DistanceBlock/DistanceBlock.js";
import StyledFloorRow from "./FloorRow.styles.js";

type FloorRowProps = {
  floorIndex: number;
  floorCount: number;
  locationData: SpaceData;
  initialCount: number;
  initialState: OccupationState;
  wsCallback?: (id: string, loc: LocationOccupationData) => void;
};

const FloorRow: React.FC<FloorRowProps> = ({
  floorIndex,
  floorCount,
  locationData,
  initialCount,
  initialState,
  wsCallback,
}: FloorRowProps) => {
  const [occupation, setOccupation] = useState<LocationOccupationData>();
  const { data: devicesKnown } = useDevices({
    spaceId: locationData.identifier,
    enabled: !!locationData.identifier,
  });
  const devicesAvailable =
    devicesKnown === undefined || devicesKnown.length > 0;
  const methodName = `lococc-${locationData.identifier}`;
  const methodFunction = useCallback(
    (data) => {
      setOccupation(data);
      wsCallback(locationData.identifier, data);
    },
    [wsCallback, locationData.identifier],
  );
  useWebSocketSetter<LocationOccupationData>(methodName, methodFunction);

  const state = OCCUPATION_STATES[occupation?.state] ?? initialState;
  const count = occupation?.curOccupation ?? initialCount;
  const maxCount = occupation?.maxOccupation ?? locationData.capacity;
  const color =
    OCCUPATION_STATE_COLORS[occupation?.state] ??
    OCCUPATION_STATE_COLOR_DEFAULT;

  const link = `/locations/${locationData.identifier}/dashboard/`;

  return (
    <StyledFloorRow>
      <div className={`floorWrapper ${floorIndex % 2 ? "odd" : ""}`}>
        {state && devicesAvailable && <DistanceBlock state={state} />}
        <h1>{floorCount - floorIndex - 1}</h1>
        <h2>
          <Link to={link}>{locationData.name}</Link>
        </h2>
        <div className="counter">
          {count} / {maxCount}
        </div>
        <div className="line"></div>
        <Link to={link}>
          <div className="floor" style={{ zIndex: floorCount - floorIndex }}>
            <div
              className={`floorBase ${devicesAvailable ? color : "blue"}`}
            ></div>
            <div className="floorSide1"></div>
            <div className="floorSide2"></div>
          </div>
        </Link>
      </div>
    </StyledFloorRow>
  );
};

export default FloorRow;
