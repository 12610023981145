import React from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UserRole } from "~/constants.js";
import AuthService from "~/services/AuthService.js";
import Header from "~/components/portal/Header/Header.js";
import StyledHeader from "~/components/portal/Header/Header.styles.js";
import TableBasic from "~/components/portal/TableBasic/TableBasic.js";
import { DeviceData } from "~/api/v1/Device/device.js";
import { useDevices } from "~/hooks/api.js";

const DevicesIndexPage: React.FC = () => {
  const { t } = useTranslation();
  // For regular users, we don't need to pass the orgId
  // For superadmins, we might want a dropdown or something
  // But we might want to make that a global "just look at this org" thing
  const { data: devices } = useDevices({});

  const columns: TableColumn<any>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row: DeviceData) => (
        <div>{t(`devices.types.${row.type.toLocaleLowerCase()}`)}</div>
      ),
      sortable: true,
    },
  ];
  if (AuthService.hasRole(UserRole.SuperAdmin))
    columns.push({
      name: "Organization",
      selector: (row: DeviceData) => row.space?.organization?.name,
      sortable: true,
    });

  return (
    <StyledDevicesIndexPage>
      <Header title={t("devices.index.header.title")}>
        {/*<Button label={t('devices.index.header.button')} url="/devices/create" />*/}
      </Header>
      <TableBasic columns={columns} data={devices} />
    </StyledDevicesIndexPage>
  );
};

const StyledDevicesIndexPage = styled.div`
  ${StyledHeader} {
    margin-bottom: 10px;
  }
`;

export default DevicesIndexPage;
