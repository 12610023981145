import { addWeeks, endOfWeek, getWeek, getYear, startOfWeek } from "date-fns";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { DayOfWeek, formateDisplayDate } from "../../../utils/date-time.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import ArrowButton from "../ArrowButton/ArrowButton.js";
import Dropdown from "../Dropdown/Dropdown.js";
import StyledDateSliderWeek from "./DateSliderWeek.styles.js";

type DateSliderWeekProps = {
  onChange: (dateTime: Date) => void;
};

const DateSliderWeek: React.FC<DateSliderWeekProps> = ({
  onChange,
}: DateSliderWeekProps) => {
  const now = new Date().valueOf();
  const startOfWeekNow = startOfWeek(now, {
    weekStartsOn: DayOfWeek.Monday,
  }).valueOf();

  const [selectedStartOfWeek, setSelectedStartOfWeek] =
    useState(startOfWeekNow);
  useEffect(() => {
    onChange(new Date(selectedStartOfWeek));
  }, []);

  const options = new Array<{ key: string; label: string }>();
  for (
    let startOfWeek = addWeeks(selectedStartOfWeek, -5).valueOf();
    startOfWeek <= startOfWeekNow;
    startOfWeek = addWeeks(startOfWeek, 1).valueOf()
  ) {
    const week = getWeek(startOfWeek, { weekStartsOn: DayOfWeek.Monday });
    const year = getYear(startOfWeek);
    options.push({
      key: String(startOfWeek.valueOf()),
      label: `Week ${week - 1} - ${year}`,
    });
  }

  const func1 = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStartOfWeekNext = Number(ev.target.value);
    setSelectedStartOfWeek(selectedStartOfWeekNext);
    onChange(new Date(selectedStartOfWeekNext));
  };

  const leftClick = () => {
    const selectedStartOfWeekNext = addWeeks(selectedStartOfWeek, -1);
    setSelectedStartOfWeek(selectedStartOfWeekNext.valueOf());
    onChange(selectedStartOfWeekNext);
  };

  const rightClick = () => {
    const selectedStartOfWeekNext = addWeeks(selectedStartOfWeek, +1);
    if (selectedStartOfWeekNext.valueOf() > startOfWeekNow) {
      return;
    }
    setSelectedStartOfWeek(selectedStartOfWeekNext.valueOf());
    onChange(selectedStartOfWeekNext);
  };

  const formatWeek = (dateTime: number | Date) => {
    const startDate = startOfWeek(dateTime, {
      weekStartsOn: DayOfWeek.Monday,
    });
    const endDate = endOfWeek(dateTime, { weekStartsOn: DayOfWeek.Monday });

    return `${formateDisplayDate(startDate)} to ${formateDisplayDate(endDate)}`;
  };

  return (
    <StyledDateSliderWeek>
      <BlockWrapper>
        <ArrowButton onClick={leftClick} mirror={true} />
        <div>
          <Formik initialValues={null} onSubmit={null}>
            <Dropdown
              name="date_week"
              options={options}
              value={selectedStartOfWeek}
              onChange={func1}
            ></Dropdown>
          </Formik>
          <span className="text">{formatWeek(selectedStartOfWeek)}</span>
        </div>
        <ArrowButton onClick={rightClick} />
      </BlockWrapper>
    </StyledDateSliderWeek>
  );
};

export default DateSliderWeek;
