import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpaceData } from "~/api/v1/Space";
import LocationCardPage from "~/components/pages/LocationCardPage";
import Header from "~/components/portal/Header";
import Button from "~/components/UI/Button";
import OrganizationSelect, {
  Organization,
  noOrg,
} from "~/components/UI/OrganizationSelect";
import { UserRole } from "~/constants.js";
import { useSpaces } from "~/hooks/api.js";
import { useFilteredSpaces, useOrgsFromSpaces } from "~/hooks/spaceUtils.js";
import AuthService from "~/services/AuthService.js";

const getHref = (space: SpaceData) => {
  return `/locations/${space.identifier}/dashboard/`;
};

const LocationsIndexPage: React.FC = () => {
  const { t } = useTranslation();
  const [organization, setOrganization] = useState<string>(noOrg.identifier);

  const isSuper = AuthService.hasRole(UserRole.SuperAdmin);
  const canCreate = AuthService.hasRole(UserRole.Admin);

  const { data: spaces } = useSpaces({});
  const indoor = useMemo(
    () => spaces?.filter((item) => item.location.type == "Building") ?? [],
    [spaces],
  );

  const orgs = useOrgsFromSpaces(indoor);
  const spacesFiltered = useFilteredSpaces({
    spaces: indoor,
    organizationId: organization == noOrg.identifier ? undefined : organization,
    superSpaceId: null,
  });

  return (
    <>
      <Header title={t("locations.index.header.title")}>
        {isSuper && (
          <OrganizationSelect
            organizations={[noOrg, ...orgs]}
            onChange={(identifier) => setOrganization(identifier)}
          />
        )}
        {canCreate && (
          <Button
            label={t("locations.index.header.button")}
            url="/locations/create"
          />
        )}
      </Header>
      <LocationCardPage spaces={spacesFiltered} getHref={getHref} />
    </>
  );
};

export default LocationsIndexPage;
