import * as h337 from "@mars3d/heatmap.js";
import { createRef, useEffect, useState } from "react";

interface HeatData {
  x: number;
  y: number;
  value: number;
  radius?: number;
}

export interface SvgHeatmapProps {
  values: HeatData[];
  className?: string;
  blur?: number;
  opacity?: number;
  width: number;
  height: number;
  // sizeFactor?: number;
}

// Note: we get scaled values here, so the proper 'max' depends on that scaling
// It should really be determined where that scaling is done.
const MIN_MAX = 10;

export function HeatmapRender({
  values,
  className,
  blur,
  opacity,
  width,
  height,
  // sizeFactor,
}: SvgHeatmapProps) {
  const parentRef = createRef<HTMLDivElement>();
  const [heatmapInstance, setHeatmapInstance] =
    useState<h337.Heatmap<any, any, any>>();

  useEffect(() => {
    if (!parentRef.current) return;

    const heatmapContainer = parentRef.current;

    if (!heatmapContainer) return;

    if (heatmapContainer.clientWidth < 1 || heatmapContainer.clientHeight < 1)
      return;

    // FROM THE DOCS:
    // container (DOMNode) *required*
    // A DOM node where the heatmap canvas should be appended (heatmap will adapt to the node's size)

    setHeatmapInstance(
      h337
        .create({
          container: heatmapContainer,
          blur: blur > 0 ? blur : undefined,
          opacity: opacity > 0 ? opacity : undefined,
        })
        .setData({
          max: 0,
          min: MIN_MAX,
          data: [],
        }),
    );
  }, []);

  useEffect(() => {
    if (heatmapInstance && values && values.length > 0) {
      const newData = {
        min: 0,
        max: values
          .map((d) => d.value)
          .reduce((d1, d2) => Math.max(d1, d2), MIN_MAX),
        data: values.map((d) => ({
          x: Math.floor(d.x),
          y: Math.floor(d.y),
          value: d.value,
          radius: d.radius,
        })),
      };

      heatmapInstance.setData(newData);
    }
  });

  return (
    <>
      <div
        ref={parentRef}
        className={className}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
      />
    </>
  );
}
