import React from "react";
import { useTranslation } from "react-i18next";
import type { OccupationState } from "../../../constants.js";
import { Image } from "../../../api/v1/image.js";
import DistanceCircle from "../../atoms/DistanceCircle/DistanceCircle.js";
import Logo from "../../atoms/Logo/Logo.js";
import StyledBigScreen from "./BigScreen.styles.js";

export type BigScreenProps = {
  count?: number;
  maxCount?: number;
  state: OccupationState;
  reconnect?: boolean;
  companyLogo?: Image;
};

const BigScreen: React.FC<BigScreenProps> = ({
  count,
  maxCount,
  state,
  reconnect,
  companyLogo,
}: BigScreenProps) => {
  const { t } = useTranslation();

  if (reconnect) {
    return (
      <StyledBigScreen>
        {companyLogo ? (
          <img className="logo" src={companyLogo.url} />
        ) : (
          <Logo />
        )}

        <div className="headerWrapper">
          <p>{t("bigScreen.subHeader.lostConnection")}</p>
        </div>
      </StyledBigScreen>
    );
  }

  return (
    <StyledBigScreen>
      {companyLogo ? <img className="logo" src={companyLogo.url} /> : <Logo />}

      <div className="headerWrapper">
        <h1>{t("bigScreen.header." + state)}</h1>
        <p>{t("bigScreen.subHeader." + state)}</p>
      </div>

      <DistanceCircle state={state} />

      <div className={"countWrapper" + (count === undefined ? " noData" : "")}>
        <div className="count">
          {count} / {maxCount}
        </div>
        <div className="countLabel">{t("bigScreen.peopleSubText")}</div>
      </div>

      <div className="poweredBy">
        {t("bigScreen.poweredBy")}
        <Logo />
      </div>
    </StyledBigScreen>
  );
};

export default BigScreen;
