import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "react";
import { API_ENDPOINT } from "../constants.js";
import axios from "../utils/axios.js";
import { parseI18nAndSetError } from "../utils/parsers.js";

type RESTServicePostParams<T> = {
  setFieldError?: any;
  url: string;
  payload: any;
  successCallback: (res: AxiosResponse<T>) => void;
  errorCallback?: (err: AxiosError<T>) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
  setError?: Dispatch<string>; //set server errors here
};

type RESTServiceGetParams<T> = {
  url: string;
  successCallback: (res: AxiosResponse<T>) => void;
  errorCallback?: (err: AxiosError<T>) => void;
};

type RESTServiceGetAsyncParams<T> = {
  url: string;
};

class RESTService {
  static postForm<T>({
    setFieldError,
    url,
    payload,
    successCallback,
    errorCallback,
    setSubmitting,
    setError,
  }: RESTServicePostParams<T>) {
    if (typeof setSubmitting == "function") setSubmitting(true);
    if (typeof setError == "function") setError(null);
    if (typeof API_ENDPOINT == "undefined") {
      alert("API_ENDPOINT not configured"!);
      return;
    }

    axios
      .post<T>(API_ENDPOINT + url, payload)
      .then((res: AxiosResponse<T>) => {
        if (typeof setSubmitting == "function") setSubmitting(false);
        // This shouldn't be needed, but leave it here just in case for now
        if (res.status < 200 || res.status >= 300) {
          console.error(
            `Got ${res.status} in success callback of POST(form) ${url}!`,
          );
          if (errorCallback)
            errorCallback(
              new AxiosError<T>(
                undefined,
                undefined,
                undefined,
                undefined,
                res,
              ),
            );
          return;
        }
        if (typeof successCallback == "function") successCallback(res);
      })
      .catch((err: AxiosError<T>) => {
        if (typeof setSubmitting == "function") setSubmitting(false);
        if (typeof setError == "function") parseI18nAndSetError(err, setError);

        if (typeof errorCallback != "undefined") {
          errorCallback(err);
        }

        if (typeof setFieldError != "undefined") {
          //TODO: fix eslint
          //TODO: fix error response in case of no connection to the server

          if (typeof err.response == "undefined") {
            alert("Server connection lost, please contact support");
            return;
          }

          const errors = (err.response?.data as any).errors;

          if (errors) {
            for (const k of Object.keys(errors)) {
              setFieldError(k, errors[k]);
            }
          }
        }
      });
  }

  static post<T>({
    url,
    payload,
    successCallback,
    errorCallback,
  }: RESTServicePostParams<T>) {
    axios
      .post<T>(API_ENDPOINT + url, payload)
      .then((res: AxiosResponse<T>) => {
        // This shouldn't be needed, but leave it here just in case for now
        if (res.status < 200 || res.status >= 300) {
          console.error(
            `Got ${res.status} in success callback of POST ${url}!`,
          );
          if (errorCallback)
            errorCallback(
              new AxiosError<T>(
                undefined,
                undefined,
                undefined,
                undefined,
                res,
              ),
            );
        } else if (typeof successCallback == "function") successCallback(res);
      })
      .catch((err: AxiosError<T>) => {
        if (typeof errorCallback != "undefined") {
          errorCallback(err);
        } else if (err.response) {
          console.error(
            `Error ${err.response.status} POST ${url}: ${err.response.data}`,
          );
        } else {
          console.error(`Error unclear POST ${url}`);
          //TODO: fix eslint
          //TODO: fix error response in case of no connection to the server
          //TODO: make general error catcher

          if (typeof err.response == "undefined") {
            alert("Server connection lost, please contact support");
            return;
          }
        }
      });
  }

  // TODO: add more async alternatives:
  static async getAsync<T>({ url }: RESTServiceGetAsyncParams<T>): Promise<T> {
    return (await axios.get<T>(API_ENDPOINT + url)).data;
  }

  static get<T>({
    url,
    successCallback,
    errorCallback,
  }: RESTServiceGetParams<T>) {
    axios
      .get<T>(API_ENDPOINT + url)
      .then((res: AxiosResponse<T>) => {
        // This shouldn't be needed, but leave it here just in case for now
        if (res.status < 200 || res.status >= 300) {
          console.error(`Got ${res.status} in success callback of GET ${url}!`);
          if (errorCallback)
            errorCallback(
              new AxiosError<T>(
                undefined,
                undefined,
                undefined,
                undefined,
                res,
              ),
            );
        } else if (typeof successCallback == "function") successCallback(res);
      })
      .catch((err: AxiosError<T>) => {
        if (typeof errorCallback != "undefined") {
          errorCallback(err);
        } else if (err.response) {
          console.error(
            `Error ${err.response.status} GET ${url}: ${err.response.data}`,
          );
        } else {
          console.error(`Error unclear GET ${url}`);
        }
      });
  }

  static putForm<T>({
    setFieldError,
    url,
    payload,
    successCallback,
    errorCallback,
    setSubmitting,
    setError,
  }: RESTServicePostParams<T>) {
    if (typeof setSubmitting == "function") setSubmitting(true);
    if (typeof setError == "function") setError(null);

    axios
      .put<T>(API_ENDPOINT + url, payload)
      .then((res: AxiosResponse<T>) => {
        if (typeof setSubmitting == "function") setSubmitting(false);
        // This shouldn't be needed, but leave it here just in case for now
        if (res.status < 200 || res.status >= 300) {
          console.error(`Got ${res.status} in success callback of PUT ${url}!`);
          if (errorCallback)
            errorCallback(
              new AxiosError<T>(
                undefined,
                undefined,
                undefined,
                undefined,
                res,
              ),
            );
          return;
        } else if (typeof successCallback == "function") successCallback(res);
      })
      .catch((err: AxiosError<T>) => {
        if (typeof setSubmitting == "function") setSubmitting(false);
        if (typeof setError == "function") parseI18nAndSetError(err, setError);

        if (typeof errorCallback != "undefined") {
          errorCallback(err);
        }

        if (typeof setFieldError != "undefined") {
          //TODO: fix eslint
          //TODO: fix error response in case of no connection to the server

          if (typeof err.response == "undefined") {
            alert("Server connection lost, please contact support");
            return;
          }

          const errors = (err.response?.data as any).errors;

          if (errors) {
            for (const k of Object.keys(errors)) {
              setFieldError(k, errors[k]);
            }
          }
        }
      });
  }

  static delete<T>({
    url,
    successCallback,
    errorCallback,
  }: RESTServiceGetParams<T>) {
    axios
      .delete<T>(API_ENDPOINT + url)
      .then((res: AxiosResponse<T>) => {
        // This shouldn't be needed, but leave it here just in case for now
        if (res.status < 200 || res.status >= 300) {
          console.error(
            `Got ${res.status} in success callback of DELETE ${url}!`,
          );
          if (errorCallback)
            errorCallback(
              new AxiosError<T>(
                undefined,
                undefined,
                undefined,
                undefined,
                res,
              ),
            );
          return;
        } else if (typeof successCallback == "function") successCallback(res);
      })
      .catch((err: AxiosError<T>) => {
        if (typeof errorCallback != "undefined") {
          errorCallback(err);
        } else if (err.response) {
          console.error(
            `Error ${err.response.status} DELETE ${url}: ${err.response.data}`,
          );
        } else {
          console.error(`Error unclear DELETE ${url}`);
        }
      });
  }
}

export default RESTService;
