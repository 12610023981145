import { HubConnectionBuilder } from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr/dist/esm/HubConnection";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { WEBSOCKET_SOCIAL } from "../constants.js";

type WSGroup = {
  organizationId: string;
  methodName: string;
};

type WSState = WSGroup[];

type joinGroup = {
  action: "join";
  group: WSGroup;
};

type leaveGroup = {
  action: "leave";
  group: WSGroup;
};

type WSAction = joinGroup | leaveGroup;

type StateRef = React.MutableRefObject<WSState>;
type Dispatch = React.Dispatch<WSAction>;

type tWsContext = {
  websocket: HubConnection;
  state: WSState;
  dispatch: Dispatch;
  reconnecting: boolean;
  // This is temporary, until we remove orgId from the websocket API altogether
  orgId: string;
};

const WSContext = createContext<tWsContext>({
  websocket: null,
  state: null,
  dispatch: () => undefined,
  reconnecting: null,
  orgId: null,
});

type WebSocketProviderProps = {
  children: React.ReactNode;
  token: string;
  organizationIdentifier: string;
};

const countWsGroup = (args: { stateRef: StateRef; group: WSGroup }): number => {
  const { stateRef, group } = args;
  const state = stateRef.current;
  return state.filter(
    (g) =>
      group.organizationId === g.organizationId && // TODO: get rid of orgId
      group.methodName === g.methodName,
  ).length;
};

const wsReducer = (state: WSState, action: WSAction): WSState => {
  switch (action.action) {
    case "join": {
      return [...state, action.group];
    }
    case "leave": {
      const newState = [...state];
      const index = newState.findIndex(
        (group) =>
          group.organizationId === action.group.organizationId &&
          group.methodName === action.group.methodName,
      );

      // toSpliced does not exist yet on our version
      newState.splice(index, 1); // Remove the first occurence
      return newState;
    }
  }
};

export const WebSocketProvider = ({
  children,
  token,
  organizationIdentifier,
}: WebSocketProviderProps) => {
  const [connection, setConnection] = useState<HubConnection>(null);
  const [finalConnection, setFinalConnection] = useState<HubConnection>(null);
  const [reconnected, setReconnected] = useState<boolean>(false);
  const [reconnecting, setReconnecting] = useState<boolean>(false);
  const [wsFunctions, wsDispatch] = useReducer(wsReducer, []);

  // PublicScreen uses token arg (returned by pslogin)
  // Regular users use authservice token (from regular login)
  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(WEBSOCKET_SOCIAL, {
        accessTokenFactory: () => token,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.previousRetryCount > 15) {
            return 600000;
          }
          // wait between 0 and 10 seconds before the next reconnect attempt.
          return retryContext.previousRetryCount * 10000;
        },
      })
      .build();

    setConnection(newConnection);
  }, []);

  const start = async () => {
    try {
      await connection.start();
      connection.onreconnected(() => {
        setReconnected(true);
        setReconnecting(false);
      });
      connection.onreconnecting(() => {
        setReconnecting(true);
      });
      setFinalConnection(connection);
    } catch (err) {
      setTimeout(start, 10000);
    }
  };

  // FIXME: this should really be in the event handler,
  // removing this effect and the 'reconnected' state
  useEffect(() => {
    if (reconnected) {
      wsFunctions.forEach((item) => {
        void connection
          .invoke(
            "Join",
            item.organizationId ? item.organizationId : organizationIdentifier,
            item.methodName,
          )
          .catch((err) => {
            console.error(`Error rejoining ${item.methodName}: ${err}`);
          });
      });
    }
    setReconnected(false);
  }, [reconnected, connection, wsFunctions]);

  // FIXME: this + async function start is almost certainly not the proper way to do this
  useEffect(() => {
    if (connection) {
      void start();
    }
  }, [connection]);

  return (
    <WSContext.Provider
      value={{
        websocket: finalConnection,
        state: wsFunctions,
        dispatch: wsDispatch,
        reconnecting: reconnecting,
        orgId: organizationIdentifier,
      }}
    >
      {children}
    </WSContext.Provider>
  );
};

export const useWebSocketContext = () => useContext<tWsContext>(WSContext);

export const useWebSocketReconnecting = () => {
  const { reconnecting } = useWebSocketContext();
  return reconnecting;
};

interface WsGroupArgs {
  websocket: HubConnection;
  stateRef: StateRef;
  dispatch: Dispatch;
  //groupName: string, // is it though?
  //groupArgs: string[],
  methodName: string; //?
  methodFunction: (message: string) => void;
  organizationId: string; // TODO: remove
}

//TODO: regarding org:
// - normal users should just use their own org?
// - public screens should use their assigned org?
// - superadmins should use the org of the space they're looking at?
// but that's the case for the first 2 too right?
// Do you ever use websockets when not looking at a specific space or location?
// If that's the case, and you have the info available, just explicitly pass it, always?
// On the other hand, is it even needed? should we just get rid of it?
// It could be useful for authorization, but we don't even do that right now.
// So: get rid of orgId, and check access on Join?

const leaveWsGroup = (args: WsGroupArgs) => {
  const {
    websocket,
    stateRef,
    dispatch,
    //groupName,
    methodName,
    methodFunction,
    organizationId,
  } = args;
  const group = {
    organizationId,
    //groupName,
    methodName,
  };

  const numJoins = countWsGroup({ stateRef, group });

  dispatch({
    action: "leave",
    group,
  });

  websocket.off(methodName, methodFunction);

  if (numJoins <= 1) {
    //void websocket.invoke("Leave", organizationId, groupName);
    void websocket.invoke("Leave", organizationId, methodName);
  }
};

export const joinWsGroup = (args: WsGroupArgs): (() => void) => {
  const {
    websocket,
    stateRef,
    dispatch,
    //groupName,
    methodName,
    methodFunction,
    organizationId,
  } = args;
  if (!websocket)
    return () => {
      /*no cleanup*/
    };

  const group = {
    organizationId,
    //groupName,
    methodName,
  };

  const numJoins = countWsGroup({ stateRef, group });

  dispatch({
    action: "join",
    group,
  });

  websocket.on(methodName, methodFunction);
  if (numJoins == 0) {
    //void websocket.invoke("Join", organizationId, groupName);
    void websocket.invoke("Join", organizationId, methodName);
  }

  return () =>
    leaveWsGroup({
      websocket,
      stateRef,
      dispatch,
      //groupName,
      methodName,
      methodFunction,
      organizationId,
    });
};
