import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeInfo } from "./base.js";
import type { SpaceOptions } from "./base.js";
import { SpaceData } from "../../api/v1/Space/space.js";

export function useSpace({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<SpaceData>): UseQueryResult<SpaceData> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = ["Spaces", spaceId];
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<SpaceData>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

export interface SpacesOptions {
  enabled?: boolean;
  placeholderData?: SpaceData[];
}

export function useSpaces({
  enabled: enabledOption,
  placeholderData,
}: SpacesOptions): UseQueryResult<SpaceData[]> {
  const enabled = enabledOption === undefined || enabledOption;
  const queryKey = ["Spaces"];

  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<SpaceData[]>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

interface InvalidateOptions {
  queryClient: QueryClient;
  spaceId: string;
}

export function invalidateSpace({ queryClient, spaceId }: InvalidateOptions) {
  const queryKey = ["Spaces", spaceId];
  queryClient.invalidateQueries({ queryKey });
}
