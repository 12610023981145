import { useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type {
  ExtendedQueryResult,
  SpaceDateOptions,
  SpaceOptions,
} from "./base.js";
import { multiPlaceholder } from "./multi.js";
import { PresenceTimeInfo } from "~/api/v1/ReportInfo/index.js";
import { PresenceTimeData } from "~/api/v1/ReportData/index.js";
import { formatApiDate } from "~/utils/date-time.js";

export function useSpacePresenceTimeZones({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<PresenceTimeInfo[]>): UseQueryResult<PresenceTimeInfo[]> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = ["Spaces", spaceId, "PresenceTimeZones"];
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<PresenceTimeInfo[]>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

export function useSpacePresenceTimeZonesLatestData({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<PresenceTimeData>): ExtendedQueryResult<PresenceTimeData> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = useMemo(
    () => [
      "Spaces",
      spaceId,
      "PresenceTimeZones",
      multiPlaceholder,
      "LatestData",
    ],
    [spaceId],
  );

  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: baseQueryFn<PresenceTimeData>,
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpacePresenceTimeZonesData({
  spaceId,
  date,
  enabled: enabledOption,
  placeholderData,
}: SpaceDateOptions<PresenceTimeData>): UseQueryResult<PresenceTimeData> {
  const enabled =
    (enabledOption === undefined || enabledOption) && !!spaceId && !!date;
  const isToday = formatApiDate(new Date()) == date;
  const queryKey = useMemo(
    () =>
      isToday
        ? [
            "Spaces",
            spaceId,
            "PresenceTimeZones",
            multiPlaceholder,
            "LatestData",
          ]
        : [
            "Spaces",
            spaceId,
            "PresenceTimeZones",
            multiPlaceholder,
            "Data",
            date,
          ],
    [spaceId, date],
  );
  const staleTime = isToday ? staleTimeData : staleTimeInfo;

  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<PresenceTimeData>,
    placeholderData,
    staleTime,
  });
}
