import React from "react";
import { Link } from "react-router-dom";
import StyledArrowButton from "./ArrowButton.styles.js";

type ArrowButtonProps = {
  mirror?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
};

const Button = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  if (disabled) return <button type="button" disabled></button>;
  else if (onClick !== undefined)
    return <button type="button" onClick={onClick}></button>;
  else return <button type="button"></button>;
};

const ArrowButton: React.FC<ArrowButtonProps> = ({
  mirror = false,
  disabled = false,
  onClick,
  href,
}: ArrowButtonProps) => {
  if (onClick !== undefined && href !== undefined)
    console.error(
      "ArrowButton: At most one of onClick and href should be set",
      onClick,
      href,
    );

  if (href === undefined || disabled)
    return (
      <StyledArrowButton mirror={mirror} disabled={disabled}>
        <Button disabled={disabled} onClick={onClick} />
      </StyledArrowButton>
    );
  else
    return (
      <StyledArrowButton mirror={mirror} disabled={disabled}>
        <Link to={href}>
          <Button disabled={disabled} onClick={onClick} />
        </Link>
      </StyledArrowButton>
    );
};

export default ArrowButton;
