import { ref, string } from "yup";

type validationRulesInput = {
  string: {
    min: string;
    valid_password: string;
    password_match: string;
  };
  mixed: {
    required: string;
  };
};

class PasswordService {
  /**
   * Only use this function on Yup schema's with the default translations 'msg' in them
   */
  static validatedPasswordRules(
    msg: validationRulesInput,
    passwordRepeat: boolean,
  ) {
    if (passwordRepeat) {
      return string()
        .min(8, msg.string.min)
        .oneOf([ref("password"), null], msg.string.password_match)
        .required(msg.mixed.required);
    } else {
      return string()
        .min(8, msg.string.min)
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])(.*){8,}$/,
          msg.string.valid_password,
        ) //password validation
        .required(msg.mixed.required);
    }
  }
}

export default PasswordService;
