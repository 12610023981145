import { useCallback, useState } from "react";
import useWebSocket from "./useWebSocket.js";

export default function useWebSocketData<DataType extends object>(
  methodName: string | null,
  initialValue?: DataType,
  organizationId?: string,
): DataType {
  const [data, setData] = useState<DataType>(initialValue);
  const methodFunction = useCallback((message: string) => {
    const data = JSON.parse(message) as DataType;
    setData(data);
  }, []);
  useWebSocket(methodName, methodFunction, organizationId);
  return data;
}
