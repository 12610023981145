import cssVars from "./cssVars.js";

/*
 * Generate a box shadow
 *
 * @param {number} offsetX - in px
 * @param {number} offsetY - in px
 * */
export function boxShadow(
  offsetX: number,
  offsetY: number,
  shadeWidth = 7,
  shadeFade = 2,
) {
  return `
    -webkit-box-shadow: ${offsetX}px ${offsetY}px ${shadeWidth}px ${shadeFade}px ${cssVars.colors.gray}; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: ${offsetX}px ${offsetY}px ${shadeWidth}px ${shadeFade}px ${cssVars.colors.gray}; /* Firefox 3.5 - 3.6 */
    box-shadow: ${offsetX}px ${offsetY}px ${shadeWidth}px ${shadeFade}px ${cssVars.colors.gray};
  `;
}
