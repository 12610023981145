import React from "react";
import { useTranslation } from "react-i18next";
import MessagePage from "../../../components/pages/MessagePage/MessagePage.js";

const EmailVerificationErrorPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <MessagePage
        title={t("auth.emailVerification.error.header")}
        message={t("auth.emailVerification.error.body")}
      />
    </>
  );
};

export default EmailVerificationErrorPage;
