import { object, string } from "yup";
import { getCurrentLanguageTranslation } from "../../../locales/helpers/index.js";

export const defaults = {
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
};

const { validationRules: msg } = getCurrentLanguageTranslation();

export const validation = object().shape({
  email: string().email(msg.string.email).required(msg.mixed.required),
  first_name: string().nullable(),
  last_name: string().nullable(),
  phone_number: string().nullable(),
});
