import { AxiosError } from "axios";
import { Dispatch } from "react";
import { ErrorData } from "../api/v1/error.js";
import { i18n } from "./i18n.js";

const doTranslate = (translation: string) => {
  return i18n.t(translation);
};

export function parseI18nAndSetError(
  err: AxiosError,
  setError: Dispatch<string>,
): void {
  if (typeof err.response == "undefined") {
    setError(doTranslate("apiErrors.unexpected"));
  }

  const error = err.response?.data as ErrorData;

  if (!error) {
    return;
  }

  if (!error.error_message_i18n || error.error_message_i18n.length == 0) {
    if (!error.error_message) {
      setError(doTranslate("apiErrors.unexpected"));
    } else {
      setError(error.error_message);
    }
  } else {
    setError(doTranslate(error.error_message_i18n));
  }
}
