import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from "yup";

// import { setLocale as setDateLocale } from 'utils/date';
import en from "../locales/en.js";
import nl from "../locales/nl.js";

export const updateValidationLanguage = () => {
  // We need only the first two chars (sometimes the language is provided as en-GB for example)
  switch (i18n.language.substr(0, 2)) {
    case "en":
      setLocale(en.translation.validationRules);
      break;

    case "nl":
    default:
      setLocale(nl.translation.validationRules);
      break;
  }
  // setDateLocale(i18n.language);
};

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
// .use(LanguageDetector)
// pass the i18n instance to react-i18next.
// i18n.use(initReactI18next);
// Ensure the Yup locale is changed too
// i18n.on('languageChanged', () => updateValidationLanguage());
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
void i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: import.meta.env.NODE_ENV === "development",

    resources: {
      en,
      nl,
    },

    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },
  })
  .then(() => {
    void i18n.changeLanguage("en");
  });

i18n.on("languageChanged", () => updateValidationLanguage());

export { i18n };
