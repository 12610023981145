import React from "react";
import { useTranslation } from "react-i18next";
import CenteredLink from "../../../components/atoms/CenteredLink/CenteredLink.js";
import MessagePage from "../../../components/pages/MessagePage/MessagePage.js";

const PasswordForgottenThankYouPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <MessagePage
        title={t("auth.forgotPassword.thankYou.header")}
        message={t("auth.forgotPassword.thankYou.body")}
      />
      <CenteredLink to="/login" title={t("auth.login.backToLogin")} />
    </>
  );
};

export default PasswordForgottenThankYouPage;
