import styled, { css } from "styled-components";
import { BreakpointString, column } from "./breakpoints.js";

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div<{
  cols?: Partial<Record<BreakpointString, number>>;
}>`
  flex-grow: 0;
  flex-wrap: wrap;

  ${(props) =>
    props.cols
      ? column(props.cols)
      : css`
          width: 100%;
        `};
`;
