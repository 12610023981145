import { useCallback, useMemo } from "react";
import type { QueryClient } from "@tanstack/react-query";
import useWebSocket from "../websockets/useWebSocket.js";
import useWebSocketMulti from "../websockets/useWebSocketMulti.js";

type DataMap<Type> = Record<string, Type>;

type Stamped = {
  time_stamp?: number;
};

interface WebSocketUpdatedQueryProps {
  queryKey: string[];
  methodName: string;
  queryClient: QueryClient;
  organizationId?: string;
}

export function useWebSocketUpdatedQuery<DataType extends Stamped>({
  queryKey,
  methodName,
  queryClient,
  organizationId,
}: WebSocketUpdatedQueryProps) {
  const methodFunction = useCallback(
    (message: string) => {
      const data: DataType = JSON.parse(message);
      if (data.time_stamp === undefined) data.time_stamp = Date.now();
      //console.log(`Updating ${queryKey.join('/')} from ${methodName} to ${JSON.stringify(data)}`);
      queryClient.setQueryData(queryKey, data);
    },
    [queryKey, queryClient],
  );
  // TODO: maybe useWebSocketSetter<DataType>?
  useWebSocket(methodName, methodFunction, organizationId);
}

interface WebSocketUpdatedMultiQueryProps {
  queryKey: string[];
  groupPrefix: string;
  items: string[];
  queryClient: QueryClient;
  organizationId?: string;
}

export function useWebSocketUpdatedMultiQuery<DataType extends Stamped>({
  queryKey,
  groupPrefix,
  items,
  queryClient,
  organizationId,
}: WebSocketUpdatedMultiQueryProps) {
  const groupMap = useMemo(
    () =>
      Object.fromEntries(items.map((item) => [`${groupPrefix}-${item}`, item])),
    [groupPrefix, items],
  );

  const methodNames = useMemo(() => Object.keys(groupMap), [groupMap]);

  const methodFunction = useCallback(
    (methodName: string, message: string) => {
      const data: DataType = JSON.parse(message);
      if (data.time_stamp === undefined) data.time_stamp = Date.now();
      const item = groupMap[methodName];
      if (item === undefined)
        console.error(
          `Got update in group ${methodName}, which we don't know how to handle`,
          groupMap,
        );
      else {
        const thisQueryKey = [
          ...queryKey.slice(0, 3),
          item,
          ...queryKey.slice(4),
        ];
        queryClient.setQueryData(thisQueryKey, data);
        queryClient.setQueryData(
          queryKey,
          (oldData: DataMap<DataType> | undefined) => {
            if (oldData === undefined) return undefined;
            const newData = { ...oldData };
            newData[item] = data;
            return newData;
          },
        );
      }
    },
    [queryClient, queryKey, groupMap],
  );

  useWebSocketMulti(methodNames, methodFunction, organizationId);
}
