import { useEffect, useMemo, useRef } from "react";
import { joinWsGroup, useWebSocketContext } from "./WebSocketProvider.js";

type Callback = (message: string) => void;
type InfoCallback = (methodName: string, message: string) => void;
type FuncPair = [string, Callback];

export default function useWebSocketMulti(
  methodNames: string[],
  methodFunction: (methodName: string, message: string) => void,
  organizationId?: string,
) {
  const { websocket, state, dispatch, orgId } = useWebSocketContext();
  const stateRef = useRef(state);
  stateRef.current = state;

  const org = organizationId ? organizationId : orgId;
  const itemFuncPairs: FuncPair[] = useMemo(
    () =>
      methodNames.map((methodName: string) => {
        const methodFunc = (message: string) =>
          methodFunction(methodName, message);
        return [methodName, methodFunc];
      }),
    [methodNames, methodFunction],
  );
  useEffect(() => {
    const cleanups = itemFuncPairs.map(([methodName, methodFunc]) =>
      joinWsGroup({
        websocket,
        stateRef,
        dispatch,
        methodName,
        methodFunction: methodFunc,
        organizationId: org,
      }),
    );
    return () => cleanups.forEach((cleanup) => cleanup());
  }, [websocket, itemFuncPairs, org]);
}
