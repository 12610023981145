import { Field } from "formik";
import React from "react";
import StyledCheckbox from "./Checkbox.styles.js";

type CheckboxProps = {
  name: string;
  label?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ name, label }: CheckboxProps) => (
  <StyledCheckbox>
    <Field type="checkbox" name={name} id={`checkbox-${name}`} />
    <label htmlFor={`checkbox-${name}`}>{label && label}</label>
  </StyledCheckbox>
);

export default Checkbox;
