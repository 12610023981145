import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OccupationState, OCCUPATION_STATES } from "../../constants.js";
import { Image } from "../../api/v1/image.js";
import { usePsLogin } from "../../hooks/useJwt.js";
import BigScreen from "../../components/pages/BigScreen/BigScreen.js";
import {
  WebSocketProvider,
  useWebSocketContext,
} from "../../websockets/WebSocketProvider.js";
import useWebSocketData from "../../websockets/useWebSocketData.js";
import { LocationOccupationData } from "../../websockets/websockets.js";

const BigScreenPage: React.FC = () => {
  const { token: magicToken } = useParams<{ token: string }>();

  const { data } = usePsLogin(magicToken);
  const jwtToken = data?.token;

  if (!jwtToken) return <></>;

  return (
    <>
      <WebSocketProvider
        token={jwtToken}
        organizationIdentifier={data.organization_identifier}
      >
        <BigScreenContent
          organizationIdentifier={data.organization_identifier}
          spaceIdentifier={data.space_identifier}
          companyLogo={data.image}
        />
      </WebSocketProvider>
    </>
  );
};

interface BigScreenContentProps {
  organizationIdentifier: string;
  spaceIdentifier: string;
  companyLogo: Image;
}

const BigScreenContent: React.FC<BigScreenContentProps> = ({
  organizationIdentifier,
  spaceIdentifier,
  companyLogo,
}: BigScreenContentProps) => {
  const { websocket, reconnecting } = useWebSocketContext();
  const methodName = `lococc-${spaceIdentifier}`;
  const data = useWebSocketData<LocationOccupationData>(
    methodName,
    null,
    organizationIdentifier,
  );
  const counterState = OCCUPATION_STATES[data?.state] ?? OccupationState.Ok;

  return (
    <BigScreen
      count={data?.curOccupation}
      maxCount={data?.maxOccupation}
      state={counterState}
      reconnect={reconnecting}
      companyLogo={companyLogo}
    />
  );
};

export default BigScreenPage;
