import RESTService from "~/services/RESTService.js";
import { formatApiDate, startOfWeek } from "~/utils/date-time.js";

export interface WeeklyDataOptions {
  spaceId: string;
  enabled?: boolean;
  date: number;
}
export interface WeeklyGateDataOptions extends WeeklyDataOptions {
  gateId: string;
}

export async function queryFnWeek<Type>({ queryKey }): Promise<Type> {
  const lastIndex = queryKey.length - 1;
  const strDate = queryKey[lastIndex];
  const urlPath = queryKey.slice(0, lastIndex).join("/");

  const dateNum: number = +strDate;
  const startDate = startOfWeek(dateNum);

  const params = new URLSearchParams();
  params.append("week", formatApiDate(startDate));

  const url = `${urlPath}?${params.toString()}`;

  return await RESTService.getAsync<Type>({
    url,
  });
}
