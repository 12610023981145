import { css } from "styled-components";
import { typoInputFont } from "./cssTypography.js";
import cssVars from "./cssVars.js";

export const defaultInputStyle = css`
  margin: 0;
  padding: 12px 16px;

  ${typoInputFont};

  border: 1px solid ${cssVars.colors.gray};
  border-radius: ${cssVars.borderRadius.default};

  &:focus {
    border: 1px solid ${cssVars.colors.blueLight};
    outline: none;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: ${cssVars.colors.gray};
  }
`;

export const defaultInputLabel = css`
  font-size: 14px;
  font-weight: 700;

  margin-bottom: 5px;
`;
