import React from "react";
import { useTranslation } from "react-i18next";
import CenteredLink from "../../atoms/CenteredLink/CenteredLink.js";
import MessagePage from "../MessagePage/MessagePage.js";
import StyledPageNotFound from "./PageNotFound.styles.js";

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledPageNotFound>
      <MessagePage title={""} message={t("apiErrors.notFoundPage")} />
      <a href="mailto:support@husense.nl">{t("apiErrors.contact")}</a>
      <CenteredLink to="/login" title={t("auth.login.goToTheLoginPage")} />
    </StyledPageNotFound>
  );
};

export default PageNotFound;
