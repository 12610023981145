import styled, { css } from "styled-components";
import cssVars from "../../../styles/cssVars.js";
import getSVGUrl from "../../../styles/helpers/getSVG.js";

const StyledArrowButton = styled.div<{ mirror: boolean; disabled: boolean }>`
  button {
    position: relative;

    width: 46px;
    height: 46px;

    ${(props) =>
      props.disabled
        ? css`
            opacity: 0.5;
          `
        : css``}

    border: 2px solid ${cssVars.colors.green};
    border-radius: 4px;

    background-color: ${cssVars.colors.white};

    ${(props) =>
      props.disabled
        ? css``
        : css`
            &:hover {
              background-color: ${cssVars.colors.green};
              cursor: pointer;

              &:before {
                background-color: ${cssVars.colors.white};
              }
            }
          `}

    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;

    &:before {
      content: "";

      mask: ${getSVGUrl("arrowright")} no-repeat 50% 50%;

      background-color: ${cssVars.colors.green};
      display: block;

      position: absolute;

      ${(props) =>
        props.mirror
          ? css`
              transform: scaleX(-1);
              right: 12px;
            `
          : css`
              right: 11px;
            `}

      bottom: 13px;

      width: 19px;
      height: 18px;

      pointer-events: none;
    }
  }
`;

export default StyledArrowButton;
