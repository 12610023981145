export function getRandom(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function getRandomInt(min: number, max: number): number {
  return Math.floor(getRandom(min, max));
}

/*
instead of using Math.min, use this in reducers. To keep typescript happy!
*/
export function minimumReducer(aggregate: number, value: number) {
  return Math.min(aggregate, value);
}

/*
instead of using Math.max, use this in reducers. To keep typescript happy!
*/
export function maximumReducer(aggregate: number, value: number) {
  return Math.max(aggregate, value);
}
