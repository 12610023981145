import React from "react";
import BlockWrapper from "../BlockWrapper/BlockWrapper.js";
import StyledFormWrapper from "./FormWrapper.styles.js";

type FormWrapperProps = {
  children: React.ReactNode;
};

const FormWrapper: React.FC<FormWrapperProps> = ({
  children,
}: FormWrapperProps) => (
  <StyledFormWrapper>
    <BlockWrapper>{children}</BlockWrapper>
  </StyledFormWrapper>
);

export default FormWrapper;
