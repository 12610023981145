import React from "react";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledHeader from "./Header.styles.js";

type HeaderProps = {
  children: React.ReactNode;
};

const Header: React.FC<HeaderProps> = ({ children }: HeaderProps) => (
  <StyledHeader>
    <BlockWrapper>{children}</BlockWrapper>
  </StyledHeader>
);

export default Header;
