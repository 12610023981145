import React, { RefObject } from "react";
import { useSpaceHeatmaps, useSpaceHeatmapsSummedData } from "~/hooks/api";
//import { formatApiDate } from "~/utils/date-time.js";
import HeatmapCalculate from "./HeatMapCalculate.js";

type HeatmapRangeProps = {
  children: React.ReactNode;
  organizationId: string;
  spaceId: string;
  daterangeBegin: string;
  daterangeEnd: string;
  radius?: number;
  scaling?: number;
  image: { width: number };
  heatmap: {
    positionX: number;
    positionY: number;
    width: number;
  };
  isDebug?: boolean;
  debugCallback?: (info: object) => void;
};

const HeatmapRange: React.FC<HeatmapRangeProps> = ({
  children,
  organizationId,
  spaceId,
  //daterangeBegin,
  //daterangeEnd,
  daterangeBegin: from,
  daterangeEnd: till,
  radius,
  scaling,
  image,
  heatmap,
  isDebug,
  debugCallback,
}: HeatmapRangeProps) => {
  const radiusMultiplier = radius ?? 1.0;
  const scalingExponent = scaling ?? 1.0;
  //const from = formatApiDate(daterangeBegin);
  //const till = formatApiDate(daterangeEnd);

  const { data: zones } = useSpaceHeatmaps({ spaceId });
  const { data: heatmapData } = useSpaceHeatmapsSummedData({
    spaceId,
    from,
    till,
  });

  // Filter out the zones that are not relevant for this space
  const dataForAllRelevantZones =
    zones && heatmapData
      ? Object.entries(heatmapData).filter(([key]) =>
          zones.map((zone) => zone.identifier).includes(key),
        )
      : [];

  const zoneDataMap: [string, number[][]][] = dataForAllRelevantZones.map(
    (item) => {
      return [item[0], item[1] ?? []];
    },
  );
  console.log(
    `Rendering ${spaceId} from ${from} to ${till}`,
    zones,
    zoneDataMap,
  );

  return (
    <HeatmapCalculate
      zones={zones}
      zoneDataMap={zoneDataMap}
      radiusMultiplier={radiusMultiplier}
      scalingExponent={scalingExponent}
      imageWidth={image?.width}
      heatmapConfig={heatmap}
      isDebug={isDebug}
      debugCallback={debugCallback}
    >
      {children}
    </HeatmapCalculate>
  );
};

export default HeatmapRange;
