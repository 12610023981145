import RESTService from "../../services/RESTService.js";

export type DataMap<Type> = Record<string, Type>;

export const multiPlaceholder = "_";

// TODO: specify the index that should be sliced out?
export async function queryFnSingle<Type>({ queryKey, meta }): Promise<Type> {
  const { queryClient } = meta;
  const key = queryKey[3];
  const value = await RESTService.getAsync<Type>({
    url: queryKey.join("/"),
  });
  const fullKey = [
    ...queryKey.slice(0, 3),
    multiPlaceholder,
    ...queryKey.slice(4),
  ];
  queryClient.setQueryData(fullKey, (oldData) => {
    if (oldData === undefined) return undefined;

    const update = {};
    update[key] = value;
    return {
      ...oldData,
      ...update,
    };
  });
  return value;
}

// TODO: specify the index that should be sliced out?
export async function queryFnMulti<Type>({
  queryKey,
  meta,
}): Promise<DataMap<Type>> {
  const { queryClient } = meta;
  const map = await RESTService.getAsync<DataMap<Type>>({
    url: queryKey.join("/"),
  });
  Object.entries(map).forEach(([key, value]) => {
    const fullKey = [...queryKey.slice(0, 3), key, ...queryKey.slice(4)];
    queryClient.setQueryData(fullKey, value);
  });
  return map;
}
