import { HourlyWeekData } from "~/api/v1/ReportData/index.js";
import { DAYS_FULL } from "~/constants.js";

export default function sumWeeklyGrid(
  gridMap: Record<string, HourlyWeekData>,
  filter?: (gateId: string) => boolean,
): HourlyWeekData {
  return gridMap
    ? Object.entries(gridMap)
        .filter(([key, val]) => filter === undefined || filter(key))
        .reduce(
          (weekAcc, [key, weekVal]) => {
            for (const [dayKey, dayVal] of Object.entries(weekVal)) {
              const dayAcc = weekAcc[dayKey];
              for (const [hourKey, hourVal] of Object.entries(dayVal)) {
                if (hourKey in dayAcc) dayAcc[hourKey] += hourVal;
                else dayAcc[hourKey] = hourVal;
              }
            }
            return weekAcc;
          },
          Object.fromEntries(
            DAYS_FULL.map((name) => [name, {}]),
          ) as HourlyWeekData,
        )
    : null;
}
