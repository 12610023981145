import { useQueryClient } from "@tanstack/react-query";
import React, { RefObject, useMemo } from "react";
import { HeatmapData } from "../../../api/v1/ReportData/index.js";
import { useSpaceHeatmaps, useSpaceHeatmapsLatestData } from "~/hooks/api";
import { useWebSocketUpdatedMultiQuery } from "../../../hooks/websocketQuery.js";
import HeatmapCalculate from "./HeatMapCalculate.js";

type HeatmapConfigProps = {
  children: React.ReactNode;
  organizationId: string;
  spaceId: string;
  radius?: number;
  scaling?: number;
  image: { width: number };
  heatmap: {
    positionX: number;
    positionY: number;
    width: number;
  };
  isDebug?: boolean;
  debugCallback?: (info: object) => void;
};

export const DEFAULTS = {
  heatmap_width: 1024,
  heatmap_pos_x: 0,
  heatmap_pos_y: 0,
  radius_multiplier: 1.0,
  scaling_exponent: 1.0,
};

const HeatmapConfig: React.FC<HeatmapConfigProps> = ({
  children,
  organizationId,
  spaceId,
  radius,
  scaling,
  image,
  heatmap,
  isDebug,
  debugCallback,
}: HeatmapConfigProps) => {
  const radiusMultiplier = radius ?? 1.0;
  const scalingExponent = scaling ?? 1.0;
  const queryClient = useQueryClient();

  const { data: zones } = useSpaceHeatmaps({ spaceId });
  const { data: heatmapData, queryKey } = useSpaceHeatmapsLatestData({
    spaceId,
    queryClient,
  });
  const groupPrefix = useMemo(() => `heatmap-${spaceId}`, [spaceId]);
  const items = useMemo(
    () => zones?.map((zone) => zone.identifier) ?? [],
    [zones],
  );

  useWebSocketUpdatedMultiQuery<HeatmapData>({
    queryKey,
    groupPrefix,
    items,
    queryClient,
    organizationId,
  });

  // Filter out the zones that are not relevant for this space
  const dataForAllRelevantZones =
    zones && heatmapData
      ? Object.entries(heatmapData).filter(([key]) =>
          zones.map((zone) => zone.identifier).includes(key),
        )
      : [];

  // NOTE: Temp fix in FE, we need to ask BE to always return grid as an array
  const zoneDataMap: [string, number[][]][] = dataForAllRelevantZones.map(
    (item) => {
      let obj = item[1];

      return [item[0], obj?.grid ?? []];
    },
  );

  return (
    <HeatmapCalculate
      zones={zones}
      zoneDataMap={zoneDataMap}
      radiusMultiplier={radiusMultiplier}
      scalingExponent={scalingExponent}
      imageWidth={image?.width}
      heatmapConfig={heatmap}
      isDebug={isDebug}
      debugCallback={debugCallback}
    >
      {children}
    </HeatmapCalculate>
  );
};

export default HeatmapConfig;
