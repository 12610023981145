import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorElement from "../ErrorElement/ErrorElement.js";
import StyledPasswordInput from "./PasswordInput.styles.js";

type PasswordInputProps = {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  autocomplete?: string;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  placeholder,
  label,
  autocomplete,
}: PasswordInputProps) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    hasNonAlpha: false,
    has8digit: false,
  });

  const strength = Object.values(validate).reduce((a, item) => {
    return item ? ++a : a;
  }, 0);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const validatePassword = (password: string) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: true }));
    } else {
      setValidate((o) => ({ ...o, hasCap: false }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: true }));
    } else {
      setValidate((o) => ({ ...o, hasLow: false }));
    }

    if (password.match(/[^a-zA-Z\d]+/g)) {
      setValidate((o) => ({ ...o, hasNonAlpha: true }));
    } else {
      setValidate((o) => ({ ...o, hasNonAlpha: false }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: true }));
    } else {
      setValidate((o) => ({ ...o, has8digit: false }));
    }
  };

  return (
    <StyledPasswordInput>
      {label && <label>{label}</label>}
      <Field
        name={name}
        placeholder={placeholder}
        type="password"
        // value={password}
        onChange={(e) => {
          formik.handleChange(e);
          handleChangePassword(e);
        }}
        autocomplete={autocomplete}
      />
      <progress
        className={`password strength-${strength}`}
        value={strength}
        max="5"
      />
      <ErrorElement name={name} />
      <ul>
        <li className={validate.has8digit ? "green" : "red"}>
          {t("auth.passwordCheck.minLength")}
        </li>
        <li className={validate.hasLow ? "green" : "red"}>
          {t("auth.passwordCheck.smallLetter")}
        </li>
        <li className={validate.hasCap ? "green" : "red"}>
          {t("auth.passwordCheck.largeLetter")}
        </li>
        <li className={validate.hasNumber ? "green" : "red"}>
          {t("auth.passwordCheck.number")}
        </li>
        <li className={validate.hasNonAlpha ? "green" : "red"}>
          {t("auth.passwordCheck.nonaplha")}
        </li>
      </ul>
    </StyledPasswordInput>
  );
};

export default PasswordInput;
