import { createTheme } from "styled-breakpoints";

export enum BreakpointString {
  xx = "xx",
  xs = "xs",
  xm = "xm",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

const themeBreakpoints: Record<BreakpointString, string> = {
  xx: "0px", // 0px errors! which is weird as it is the default for xs
  xs: "320px",
  xm: "440px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

export const theme = createTheme(themeBreakpoints);

type ValidCssValue = string | number;

/*
 *
 * Function that converts either an array or object (with breakpoints as keys)
 * to a string with css.
 * values: object containing the css values to be set per bp
 *          - object based on keys {xs: 1, sm: 2 etc.
 * valueFunc: used to transform the values to usable css values
 *          - e.g. simply add 'px' --- (val) => `${val}px`
 *          - e.g. convert to percentage --- (val) => `${100 * val}%`
 *
 */
export const cssRulePerBreakpoint = <T>(
  values: T | Partial<Record<BreakpointString, T>>,
  valueFunc: (val: T) => ValidCssValue,
): string | null => {
  if (typeof values !== "object") {
    // If string given, do not use any breakpoints, just return value with prop
    return `${valueFunc(values)}`;
  }

  const keys = Object.keys(values) as BreakpointString[];

  return keys.reduce((result, breakpoint) => {
    const value = values[breakpoint];

    return `${result} ${breakpointMinMax({
      css: `${valueFunc(value)}`,
      min: breakpoint,
    })}`;
  }, "");
};

export const breakpointMinMax = ({
  css = null,
  min = null,
  max = null,
}: {
  css: string | null;
  min?: BreakpointString | null;
  max?: BreakpointString | null;
}): string | null => {
  if (min && max) {
    return `@media(min-width: ${theme["styled-breakpoints"].breakpoints[min]}) and (max-width: ${theme["styled-breakpoints"].breakpoints[max]}) {${css}}`;
  }

  if (min) {
    if (parseInt(theme["styled-breakpoints"].breakpoints[min]) > 0) {
      return `@media(min-width: ${theme["styled-breakpoints"].breakpoints[min]}) {${css};}`;
    }

    return `${css};`;
  }

  if (max) {
    return `@media(max-width: ${theme["styled-breakpoints"].breakpoints[max]}) {${css}}`;
  }

  return null;
};

export const column = (values: Partial<Record<BreakpointString, number>>) =>
  cssRulePerBreakpoint(values, (val) => `width: ${100 * val}%`);
