import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import HeatMap from "~/components/Dashboard/HeatMap/HeatMap.js";
import ZoneList from "~/components/Dashboard/ZoneList/ZoneList.js";
import Header from "~/components/portal/Header/Header.js";
import { useSpace } from "~/hooks/api.js";
import { usePsLogin } from "~/hooks/useJwt.js";
import { WebSocketProvider } from "~/websockets/WebSocketProvider.js";

const pxStrToNumber = (pxStr: string): number | undefined => {
  if (!pxStr.endsWith("px")) return undefined;
  const attempt = +pxStr.substring(0, pxStr.length - 2);
  if (isNaN(attempt)) return undefined;
  return attempt;
};

const TvDashboardPage: React.FC = () => {
  const { token: magicToken } = useParams<{
    token: string;
  }>();
  const { data } = usePsLogin(magicToken);
  const { data: space } = useSpace({ spaceId: data?.space_identifier });

  const jwtToken = data?.token;
  const spaceId = data?.space_identifier;
  const organizationId = data?.organization_identifier;

  const divRef = useRef<HTMLDivElement>(null);

  const updateDimensions = () => {
    const time = +new Date();
    if (divRef.current) {
      const headerHeight =
        (divRef.current.parentElement?.parentElement?.firstChild as HTMLElement)
          ?.offsetHeight ?? 0;
      const headerOffsetStr = window.getComputedStyle(
        divRef.current,
      ).paddingTop;
      const headerOffset = pxStrToNumber(headerOffsetStr) ?? 0;

      const windowWidth = window.innerWidth - 550;
      const windowHeight =
        Math.min(window.innerHeight, 716) - headerHeight - headerOffset;
      // Note re smart@sea: "smart screen" has a resolution (or effective resolution) of just 1280x720
      // We just removed a lot of gaps and spacing, but we want them present for normal screens
      // So this should be done automatically, and preferably depending on the number of cards we want to fit.
      // (because we want the columns to wrap on tv screens so the cards all fit,
      // which means we have to shrink the heatmap)

      divRef.current.style.maxWidth = `${windowWidth}px`;
      divRef.current.style.maxHeight = `${windowHeight}px`;
    }
  };

  // This seems to work well for the initial render
  useEffect(() => {
    if (!jwtToken || !space) return;
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, [jwtToken, space]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  if (!jwtToken || !space) return <span>Loading...</span>;

  return (
    <WebSocketProvider token={jwtToken} organizationIdentifier={organizationId}>
      <div className="bg-gray-50">
        <div
          className="relative h-screen max-w-full px-4 gap-8"
          style={{
            aspectRatio: "16/9",
          }}
        >
          <div className="px-0 pt-0 mb-4">
            <Header
              titleClassName="text-2xl font-medium"
              title={space?.name ?? "Dashboard"}
            />
          </div>

          <div className="px-0 flex justify-between flex-shrink gap-4">
            <div className="py-0 w-full" ref={divRef}>
              <HeatMap spaceId={spaceId} />
            </div>
            <div className="py-0 flex-grow flex-shrink-0 flex flex-col">
              <div
                className="flex flex-col gap-2 flex-wrap"
                style={{
                  maxHeight: window.innerHeight - 68 - 64 - 90,
                  maxWidth: 245, // 220 is about the minimum
                }}
              >
                <ZoneList organizationId={organizationId} spaceId={spaceId} />
              </div>
              <div className="flex-grow"></div>
              <div
                className="flex flex-row"
                style={{ right: 0, bottom: 0, position: "relative" }}
              >
                <div className="flex-grow"></div>
                <div>
                  <p className="mt-auto pt-8 pb-2 text-gray-400">Powered by:</p>
                  <svg
                    width="243"
                    height="40"
                    viewBox="0 0 243 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1304_37)">
                      <path
                        d="M33.3338 33.0215H26.8613V39.1991H33.3338V33.0215Z"
                        fill="#36A9E1"
                      />
                      <path
                        d="M70.2601 9.1582H63.7876V15.3358H70.2601V9.1582Z"
                        fill="#2FAC66"
                      />
                      <path
                        d="M43.3988 9.16895H36.9263V15.3466H43.3988V9.16895Z"
                        fill="#36A9E1"
                      />
                      <path
                        d="M22.3536 8.36837C20.5473 8.38462 18.7657 8.79774 17.1304 9.57956C15.4951 10.3614 14.0446 11.4935 12.8778 12.8986C12.9395 12.298 12.9395 10.4333 12.9395 9.56385V0H6.47266V39.1992H12.9395V18.693C15.4264 15.7586 18.1714 14.3 20.9783 14.3C24.2454 14.3 26.8613 16.2963 26.8613 20.592V33.0216H33.3338V19.6253C33.3338 12.7613 29.2808 8.36837 22.3536 8.36837Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M63.7873 27.4163C63.7873 32.141 60.7167 34.2803 56.8601 34.2803C53.0036 34.2803 49.961 32.141 49.961 27.4163V9.15234H43.3931V28.28C43.3931 34.6064 48.3611 40.0003 56.8658 40.0003C65.3704 40.0003 70.2655 34.6064 70.2655 28.28V15.3471H63.7873V27.4163Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M90.514 13.8943C93.1973 13.8943 95.8076 15.2271 98.1653 17.0918L101.691 12.5158C98.5756 9.7764 94.5774 8.30571 90.4634 8.38593C83.9909 8.38593 79.4158 12.1154 79.4158 17.1089C79.4158 21.9023 82.6829 24.1674 87.7801 25.9692L89.4642 26.5412C93.2534 27.874 94.9543 28.6748 94.9543 30.8026C94.9543 32.6731 93.3881 34.4692 89.728 34.4692C87.4377 34.4692 84.1144 33.0678 81.3581 30.7397L77.8945 35.6017C81.1858 38.4796 85.3913 40.0408 89.728 39.9947C97.9632 39.9947 101.685 35.0011 101.685 30.7397C101.685 26.2781 99.1365 23.3495 93.2646 21.2159L89.8179 19.9575C87.2075 19.0251 86.1578 18.3559 86.1578 16.76C86.1353 15.0269 88.1001 13.8943 90.514 13.8943Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M123.651 8.36816C114.962 8.36816 108.618 14.9633 108.618 24.1497C108.618 33.336 115.079 39.9998 123.848 39.9998C130.124 39.9998 135.547 36.871 138.163 30.945L130.798 30.7448C129.293 33.0729 126.683 34.1768 123.87 34.1768C119.228 34.1768 116.09 30.7791 115.568 26.1688H138.769V26.1059C138.746 13.7621 131.561 8.36816 123.651 8.36816ZM115.545 21.3526C116.331 17.0912 119.34 14.1625 123.719 14.1625C125.658 14.1434 127.533 14.875 128.963 16.2096C130.394 17.5442 131.274 19.3823 131.426 21.3526H115.545Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M163.126 8.36825C161.297 8.36153 159.488 8.7637 157.827 9.54642C156.167 10.3291 154.695 11.4734 153.516 12.8985C153.583 12.2979 153.583 10.4332 153.583 9.56373V9.15189H147.245V39.1819H153.712V18.6929C156.199 15.7585 158.944 14.2942 161.751 14.2942C165.018 14.2942 167.634 16.2905 167.634 20.5862V39.1991H174.106V19.6252C174.106 12.7612 170.053 8.36825 163.126 8.36825Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M194.495 13.8943C197.173 13.8943 199.789 15.227 202.141 17.0917L205.672 12.5157C202.557 9.77683 198.559 8.30621 194.445 8.38589C187.978 8.38589 183.403 12.1153 183.403 17.1089C183.403 21.9023 186.67 24.1674 191.767 25.9692L193.451 26.5412C197.24 27.8739 198.941 28.6747 198.941 30.8026C198.941 32.673 197.369 34.4691 193.709 34.4691C191.425 34.4691 188.096 33.0677 185.345 30.7397L181.881 35.6074C185.17 38.4862 189.374 40.0477 193.709 40.0004C201.944 40.0004 205.672 35.0068 205.672 30.7454C205.672 26.2838 203.123 23.3551 197.251 21.2216L193.788 19.9575C191.172 19.0251 190.128 18.3559 190.128 16.76C190.117 15.0268 192.076 13.8943 194.495 13.8943Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M242.727 26.1517C242.727 13.7621 235.536 8.36816 227.632 8.36816C218.937 8.36816 212.599 14.9633 212.599 24.1497C212.599 33.336 219.066 39.9998 227.823 39.9998C234.099 39.9998 239.522 36.871 242.138 30.945L234.756 30.7448C233.252 33.0729 230.636 34.1768 227.823 34.1768C223.186 34.1768 220.048 30.7791 219.526 26.1688H242.727V26.1517ZM219.526 21.3526C220.312 17.0912 223.316 14.1625 227.694 14.1625C229.634 14.1433 231.509 14.8747 232.941 16.2092C234.372 17.5437 235.254 19.3818 235.407 21.3526H219.526Z"
                        fill="#2D2E83"
                      />
                      <path
                        d="M6.4725 9.16895H0V15.3466H6.4725V9.16895Z"
                        fill="#2FAC66"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1304_37">
                        <rect width="242.727" height="40" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {/*<div className="flex-grow"></div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebSocketProvider>
  );
};

export default TvDashboardPage;
