import React from "react";
import { NavLink } from "react-router-dom";
import { Image } from "../../../api/v1/image.js";
import Icon, { IconName } from "../../atoms/Icon/Icon.js";
import SmallCircleImage from "../../atoms/SmallCircleImage/SmallCircleImage.js";
import LogoutButton from "../../auth/LogoutButton/LogoutButton.js";
import StyledNavigation from "./Navigation.styles.js";

type NavigationProps = {
  links: {
    icon: IconName;
    link: string;
  }[];
  profileImage?: Image;
};

const Navigation: React.FC<NavigationProps> = ({
  links,
  profileImage,
}: NavigationProps) => {
  return (
    <StyledNavigation>
      <div className="iconWrapper">
        <Icon name="husense_logo_cut" size={40} />
      </div>
      {links.map((item, index) => (
        // <NavLink to={item.link} activeClassName="active" key={`side-menu-${index}`}>
        <NavLink
          to={item.link}
          className={({ isActive }) => (isActive ? " active" : "")}
          key={`side-menu-${index}`}
        >
          <Icon name={item.icon} size={24} />
        </NavLink>
      ))}
      <div className="pullDown">
        <LogoutButton />
        <SmallCircleImage image={profileImage} />
      </div>
    </StyledNavigation>
  );
};

export default Navigation;
