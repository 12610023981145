import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { OccupationState, UserRole } from "../../../constants.js";
import { useSpaces } from "../../../hooks/api.js";
import AuthService from "../../../services/AuthService.js";
import FloorOverview from "../../../components/Dashboard/FloorOverview/FloorOverview.js";
import StyledFloorOverview from "../../../components/Dashboard/FloorOverview/FloorOverview.styles.js";
import MiniInfoCardCapacity from "../../../components/Dashboard/MiniInfoCardCapacity/MiniInfoCardCapacity.js";
import Button from "../../../components/UI/Button/Button.js";
import Header from "../../../components/portal/Header/Header.js";
import StyledHeader from "../../../components/portal/Header/Header.styles.js";
import { Col, Row } from "../../../styles/Grid.js";
import { LocationOccupationData } from "../../../websockets/websockets.js";

type counterStateItem = {
  id: string;
  occ: number;
};

const LocationsFloorIndexPage: React.FC = () => {
  const history = useNavigate();
  const path = useLocation();
  const [counterStateList, setCounterStateList] = useState<counterStateItem[]>(
    [],
  );

  const canCreate = AuthService.hasRole(UserRole.Admin);

  useEffect(() => {
    if (path.pathname == "/") {
      history("/locations");
    }
  }, []);

  // TODO: use Locations w/ types to filter instead
  const { data: spaces } = useSpaces({});
  const locations = useMemo(
    () =>
      [...(spaces ?? [])]
        ?.reverse()
        ?.filter((space) => space.location.type == "Building"), // TODO: and parent is Apollo14
    [spaces],
  );

  const counterMaxNumber = useMemo(() => {
    return (locations ?? []).reduce((acc, loc) => {
      return acc + loc.capacity;
    }, 0);
  }, [locations]);

  const counterNumber = useMemo(() => {
    return (counterStateList ?? []).reduce((acc, counter) => {
      return acc + counter.occ;
    }, 0);
  }, [counterStateList]);

  const counterState = useMemo(() => {
    let state = OccupationState.Ok;
    if (counterNumber > counterMaxNumber * 0.6) state = OccupationState.Warning;
    if (counterNumber > counterMaxNumber * 0.8) state = OccupationState.Danger;

    return state;
  }, [counterNumber, counterMaxNumber]);

  const wsCallback = useCallback((id: string, loc: LocationOccupationData) => {
    const { curOccupation } = loc;

    setCounterStateList((counterStateList) => {
      const list = counterStateList.filter((item) => item.id != id);

      list.push({ id: id, occ: curOccupation });

      return list;
    });
  }, []);

  return (
    <StyledLocationsFloorIndexPage>
      {/*<Header title={t('locations.index.header.title')}>*/}
      <Header title="Apollo 14">
        {canCreate && <Button label="Add floor" url="/locations/create" />}
      </Header>

      <Row>
        <Col cols={{ lg: 1, xl: 9 / 12 }} className="pushRight">
          <FloorOverview locations={locations} wsCallback={wsCallback} />
        </Col>
        <Col cols={{ lg: 1, xl: 3 / 12 }}>
          <MiniInfoCardCapacity
            count={counterNumber}
            maxCount={counterMaxNumber}
            state={counterState}
          />
        </Col>
      </Row>
    </StyledLocationsFloorIndexPage>
  );
};

const StyledLocationsFloorIndexPage = styled.div`
  ${StyledHeader} {
    margin-bottom: 20px;
  }

  ${Col} {
    &.pushRight {
      padding-right: 20px;
    }

    ${down("lg")} {
      &.pushRight {
        padding-right: 0px;
      }
    }
  }

  ${StyledFloorOverview} {
    margin-bottom: 20px;
  }
`;

export default LocationsFloorIndexPage;
