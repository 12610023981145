import { hour } from "msecs";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Plot from "react-plotly.js";
import { PopularTimesData } from "../../../api/v1/ReportData/index.js";
import {
  useSpaceGatePopularTimesData,
  usePopularTimesData,
} from "../../../hooks/api.js";
import cssVars from "../../../styles/cssVars.js";
import { parseHourMinute } from "../../../utils/date-time.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledPopularTimes from "./PopularTimes.styles.js";

type PopularTimesProps = {
  spaceId: string;
  useGates: boolean | undefined;
  startTime?: string;
  endTime?: string;
};

type ArrayPoint = {
  x: string;
  y: number;
  marker: string;
};

const PopularTimes: React.FC<PopularTimesProps> = ({
  spaceId,
  useGates,
  startTime,
  endTime,
}: PopularTimesProps) => {
  const { t } = useTranslation();

  const { data: popularTimesDataOld } = usePopularTimesData({
    spaceId,
    enabled: useGates === false,
  });
  const { data: gatePopularTimesData } = useSpaceGatePopularTimesData({
    spaceId,
    enabled: useGates === true,
  });

  const popularTimesData = gatePopularTimesData ?? popularTimesDataOld;

  const startTimeHour = parseHourMinute(startTime) / hour;
  const endTimeHour = parseHourMinute(endTime) / hour;

  const pointArray = useMemo<ArrayPoint[]>(() => {
    if (!popularTimesData) return [];

    const array = new Array<ArrayPoint>();
    const sum = Object.values(popularTimesData).reduce(
      (acc, val) => acc + val,
      0,
    );
    const avg = sum / 24; // For buildings open 8 hours a day, this massively skews the average...

    for (let i = startTimeHour; i <= endTimeHour; i++) {
      const x = `${i}:00`;
      const y = popularTimesData[i] ?? 0;

      const marker =
        y > avg
          ? cssVars.colors.red
          : y > avg / 2
            ? cssVars.colors.yellow
            : cssVars.colors.green;

      array.push({ x, y, marker });
    }
    return array;
  }, [popularTimesData, startTime, endTime]);

  const canShowGraph = pointArray.length >= endTimeHour - startTimeHour;

  return (
    <StyledPopularTimes>
      <BlockWrapper>
        <div className="title">{t("dashboard.popularTimes.header")}</div>
        {canShowGraph && (
          <Plot
            data={[
              {
                type: "bar",
                x: pointArray.map((item) => item.x),
                y: pointArray.map((item) => item.y),
                marker: {
                  color: pointArray.map((item) => item.marker),
                },
                hoverinfo: "y",
              },
            ]}
            layout={{
              autosize: true,
              margin: { t: 0, l: 0, r: 0, b: 15 },
              font: { size: 8 },
              showlegend: false,
              // modebar: false,
              hovermode: "closest",
              xaxis: {
                showgrid: false,
                zeroline: false,
                fixedrange: true,
                tickangle: 40,
                automargin: true,
              },
              yaxis: {
                showgrid: false,
                zeroline: false,
                fixedrange: true,
              },
            }}
            useResizeHandler={true}
            config={{ displayModeBar: false }}
          />
        )}
      </BlockWrapper>
    </StyledPopularTimes>
  );
};

export default PopularTimes;
