import { AxiosResponse } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/UI/Button/Button.js";
import Dropdown from "../../../components/UI/Dropdown/Dropdown.js";
import SearchDropdown from "../../../components/UI/SearchDropdown/SearchDropdown.js";
import TextInput from "../../../components/UI/TextInput/TextInput.js";
import BlockWrapper from "../../../components/atoms/BlockWrapper/BlockWrapper.js";
import FormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.js";
import ValueBlock from "../../../components/atoms/ValueBlock/ValueBlock.js";
import { DEVICE_TYPES } from "../../../constants.js";
import { dropdownValues } from "../../../react-app-env.js";
import RESTService from "../../../services/RESTService.js";
import { Col, Row } from "../../../styles/Grid.js";
import { devicePostValues, deviceValues } from "../devices.js";
import {
  DeviceData,
  CreateDeviceResponse,
} from "../../../api/v1/Device/device.js";
import { useDevice, useSpaces } from "../../../hooks/api.js";
import { defaults, validation } from "./schema.js";

const LocationsFormPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [connString, setConnString] = useState<string>(null);

  const { data: spaces } = useSpaces({});
  const locations: dropdownValues = spaces?.map((space) => ({
    key: space.identifier,
    label: space.name,
  }));

  const deviceTypes = DEVICE_TYPES.map((item) => {
    return {
      key: item.value,
      label: t(`devices.types.${item.value.toLowerCase()}`),
    };
  });
  deviceTypes.shift(); // Remove the 'None' type

  const { data: initDevice } = useDevice({
    enabled: !!id,
    deviceId: id,
    staleTime: Infinity,
    // Make sure Query doesn't update while you're editng the form
    // Does mean we need to invalidate it on PUT
  });
  const initVals: devicePostValues =
    id && initDevice
      ? {
          type: deviceTypes[0]?.key,
          ...initDevice,
          space_identifier: initDevice.space.identifier,
        }
      : {
          type: deviceTypes[0]?.key,
          ...defaults,
        };

  if ((id && !initDevice) || !locations || locations.length == 0) {
    return <></>;
  }

  if (connString) {
    return (
      <>
        <BlockWrapper>
          <ValueBlock label={t("devices.form.connString")} value={connString} />
          <Button label={t("devices.form.buttonBack")} url="/devices" />
        </BlockWrapper>
      </>
    );
  }

  return (
    <>
      <Formik
        initialValues={initVals}
        validationSchema={validation}
        onSubmit={(
          values: deviceValues,
          { setSubmitting, setFieldError }: FormikHelpers<deviceValues>,
        ) => {
          const payload = {
            name: values.name,
            type: values.type,
            space_identifier: values.space_identifier,
          };

          if (!id) {
            void RESTService.postForm<CreateDeviceResponse>({
              url: "devices",
              payload,
              successCallback(res: AxiosResponse<CreateDeviceResponse>): void {
                setConnString(res.data.connection_string);
              },
              setFieldError,
              setSubmitting,
            });
          } else {
            // TODO: wrap these kind of things in useMutation
            // that way, we can automatically invalidate existing queries
            void RESTService.putForm<DeviceData>({
              url: `devices/${id}`,
              payload,
              successCallback() /*res: AxiosResponse<DeviceData>,*/
              : void {
                history("/devices");
              },
              setFieldError,
              setSubmitting,
            });
          }
        }}
      >
        {({ isSubmitting }) => (
          <FormWrapper>
            <Form>
              <Row>
                <Col>
                  <TextInput name="name" label={t("devices.form.name")} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Dropdown
                    name="type"
                    label={t("devices.form.type")}
                    options={deviceTypes}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SearchDropdown
                    name="space_identifier"
                    label={t("devices.form.space")}
                    options={locations}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    label={t("button.submit")}
                    isSubmitting={isSubmitting}
                    submitButton={true}
                  />
                </Col>
              </Row>
            </Form>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default LocationsFormPage;
