import { useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { object } from "yup";
import { HeatmapConfigPost, HeatmapConfigValues } from "~/api/v1/HeatmapConfig";
import { Image as ImageApiType } from "~/api/v1/image.js";
import Button from "~/components/UI/Button/Button.js";
import Checkbox from "~/components/UI/Checkbox/Checkbox.js";
import CroppedFileUpload from "~/components/UI/CroppedFileUpload/CroppedFileUpload.js";
import StyledTextInput from "~/components/UI/TextInput/TextInput.styles.js";
import RESTService from "~/services/RESTService.js";
import { formatApiDate } from "~/utils/date-time.js";
import { HeatmapInfo } from "../../../api/v1/ReportInfo/heatmap.js";
import HeatmapConfig, {
  DEFAULTS,
} from "../../../components/Dashboard/HeatMap/HeatMapConfig.js";
import HeatmapRange from "~/components/Dashboard/HeatMap/HeatMapRange.js";
import Grid from "../../../components/UI/Grid/Grid.js";
import BlockWrapper from "../../../components/atoms/BlockWrapper/BlockWrapper.js";
import Header from "../../../components/portal/HeaderNew/Header.js";

import {
  invalidateSpace,
  useSpace,
  useSpaceHeatmapLatestData,
  useSpaceHeatmaps,
} from "../../../hooks/api.js";

type StatProps = {
  label: string;
  value: any;
};

type StatGroupProps = {
  group: [string, any][];
};

type StatsCardsProps = {
  spaceId: string;
  zone: HeatmapInfo;
};

function Stat({ label, value }: StatProps) {
  const val: string = value;

  return (
    <div className="flex flex-col gap-y-0.5">
      <div className="text-gray-700">{label}</div>
      <div className="font-bold tabular-nums">{val}</div>
    </div>
  );
}

function StatGroup({ group }: StatGroupProps) {
  return (
    <div className="flex pt-2 justify-between">
      {group
        .filter(([key, value]) => !!value)
        .map(([key, value]) => (
          <Stat key={key} label={key} value={value} />
        ))}
    </div>
  );
}

function DebugInfoCard({ info }: { info: object }) {
  return (
    <BlockWrapper key="info">
      <StatGroup group={Object.entries(info)} />
    </BlockWrapper>
  );
}

const StatsCard = ({ spaceId, zone }: StatsCardsProps) => {
  const queryClient = useQueryClient();
  const heatmapZoneId = zone.identifier;
  // Todo: we really shouldn't need spaceId here
  const { data } = useSpaceHeatmapLatestData({
    spaceId,
    heatmapZoneId,
    queryClient,
  });
  const [xmin, xmax] = zone.coordinates.reduce(
    ([l, r], [x, y]) => [x < l ? x : l, x > r ? x : r],
    [Infinity, -Infinity],
  );
  const [ymin, ymax] = zone.coordinates.reduce(
    ([l, r], [x, y]) => [y < l ? y : l, y > r ? y : r],
    [Infinity, -Infinity],
  );
  const width = xmax - xmin;
  const height = ymax - ymin;
  const grid_w = data?.grid?.length ?? 0;
  const grid_h = data?.grid[0]?.length ?? 0;

  return (
    <BlockWrapper>
      <div>{zone.identifier}</div>
      <div>{zone.name}</div>
      <div>{zone.description}</div>
      {!!zone.coordinates && (
        <div>
          {zone.coordinates
            .map(([x, y]) => `[${x},${y}]`)
            .reduce((acc, val) => (acc ? acc + " " + val : val), "")}
        </div>
      )}
      <StatGroup
        group={Object.entries(zone).filter(([key, value]) =>
          key.startsWith("grid_"),
        )}
      />
      <StatGroup
        group={Object.entries({
          timestamp: data && new Date(data.time_stamp).toISOString(),
          dimensions:
            isFinite(width) &&
            `${width}x${height} m (${xmin} - ${xmax}, ${ymin} - ${ymax})`,
          grid:
            !!data &&
            `${grid_w}x${grid_h}` +
              (isFinite(width)
                ? ` (${width / grid_w}x${height / grid_h} m)`
                : ""),
        })}
      />
    </BlockWrapper>
  );
};

const validation = object().shape({});

export type HeatmapEditorProps = {
  heatmapX: number;
  heatmapY: number;
  heatmapWidth: number;
};

const FormikForwarder = ({
  setRadius,
  setScaling,
  setHeatmapEditor,
}: {
  setRadius: (radius: number) => void;
  setScaling: (exponent: number) => void;
  setHeatmapEditor: (config: HeatmapEditorProps) => void;
}) => {
  const formikContext = useFormikContext();
  const { values: values_raw } = formikContext;

  const values = values_raw as {
    radius_multiplier: number;
    scaling_exponent: number;
    heatmap_pos_x: number;
    heatmap_pos_y: number;
    heatmap_width: number;
  };

  useEffect(() => {
    setRadius(values.radius_multiplier);
  }, [values.radius_multiplier]);
  useEffect(() => {
    setScaling(values.scaling_exponent);
  }, [values.scaling_exponent]);
  useEffect(() => {
    setHeatmapEditor({
      heatmapX: values.heatmap_pos_x,
      heatmapY: values.heatmap_pos_y,
      heatmapWidth: values.heatmap_width,
    });
  }, [values.heatmap_pos_x, values.heatmap_pos_y, values.heatmap_width]);

  return <></>;
};

const AreaHeatmapTest: React.FC = () => {
  const { id: spaceId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const spaceOptions = { spaceId };
  const [radius, setRadius] = useState<number>(1.0);
  const [scaling, setScaling] = useState<number>(1.0);

  const [image, setImage] = useState<ImageApiType>();
  const [imageWidth, setImageWidth] = useState(0);

  const [initialValues, setInitialValues] = useState<HeatmapConfigValues>(); // This is *only* for Formik
  // For actual usage, just use the config'd values, or values in the form directly (FormikForwarder)

  const [heatmapKey, setHeatmapKey] = useState(0);
  const [heatmapEditor, setHeatmapEditor] = useState<HeatmapEditorProps>({
    heatmapWidth: 800,
    heatmapX: 0,
    heatmapY: 0,
  });

  const [debugInfo, setDebugInfo] = useState<object>({});

  const [renderDaterange, setRenderDaterange] = useState<[string, string]>();
  const [daterangeBegin, setDaterangeBegin] = useState<string>(
    formatApiDate(new Date()),
  );
  const [daterangeEnd, setDaterangeEnd] = useState<string>(
    formatApiDate(new Date()),
  );

  const { data: space } = useSpace(spaceOptions);
  const { data: heatmapZones } = useSpaceHeatmaps(spaceOptions);
  const organizationId = space?.organization?.identifier;

  useEffect(() => {
    if (space) {
      const { heatmap_config: config } = space;

      if (!initialValues) {
        // This is deserializing the API values to feed into Formik
        setInitialValues({
          heatmap_width_hasval:
            config !== null && config.heatmap_width !== null,
          heatmap_width: config?.heatmap_width ?? DEFAULTS.heatmap_width,
          heatmap_pos_x_hasval:
            config !== null && config.heatmap_pos_x !== null,
          heatmap_pos_x: config?.heatmap_pos_x ?? DEFAULTS.heatmap_pos_x,
          heatmap_pos_y_hasval:
            config !== null && config.heatmap_pos_y !== null,
          heatmap_pos_y: config?.heatmap_pos_y ?? DEFAULTS.heatmap_pos_y,
          radius_multiplier_hasval:
            config !== null && config.radius_multiplier !== null,
          radius_multiplier:
            config?.radius_multiplier ?? DEFAULTS.radius_multiplier,
          scaling_exponent_hasval:
            config !== null && config.scaling_exponent !== null,
          scaling_exponent:
            config?.scaling_exponent ?? DEFAULTS.scaling_exponent,
        });
      }

      if (!image) {
        setImage(config?.image);

        const img = new Image(); // Actual <img>
        img.src = config?.image?.url;

        img.onload = () => {
          setImageWidth(img.width);
        };
      }
    }
  }, [space]);

  const shouldRenderRangeHeatmap =
    renderDaterange && renderDaterange[0] && renderDaterange[1];

  const globalZone = {
    ...(heatmapZones ?? []).reduce(
      (acc, zone) => ({
        description: `${acc.description} ${zone.description}`,
        coordinates: [...acc.coordinates, ...zone.coordinates],
        grid_unit:
          acc.grid_unit === undefined || acc.grid_unit == zone.grid_unit
            ? zone.grid_unit
            : NaN,
      }),
      {
        description: "",
        coordinates: [],
        grid_unit: undefined,
      },
    ),
    identifier: null,
    name: "global",
  };

  if (initialValues == null) return <></>;

  return (
    <StyledAreaHeatmapTest>
      <Header>
        <h2>
          {space?.name} - {space?.identifier}
        </h2>
      </Header>
      <Grid
        gap={16}
        numberOfColumns={{
          xl: 3,
        }}
      >
        <Grid.Cell
          columnSpan={{
            xl: 2,
          }}
        >
          <div className="space-y-20 pt-4">
            <section className="relative">
              {/* TODO: Move image into componenet instead using children 👈 blocked by weird heatmap rendering. */}
              <HeatmapConfig
                isDebug={true}
                key={heatmapKey}
                organizationId={organizationId}
                spaceId={spaceId}
                radius={radius}
                scaling={scaling}
                image={{ width: imageWidth }}
                heatmap={{
                  positionX: heatmapEditor.heatmapX,
                  positionY: heatmapEditor.heatmapY,
                  width: heatmapEditor.heatmapWidth,
                }}
                debugCallback={setDebugInfo}
              >
                {/* 👆 We want to have image HTML inside of the component for better code colocation. */}
                {image?.url ? (
                  <img src={image.url} className="w-full h-auto" />
                ) : (
                  <div className="image" />
                )}
              </HeatmapConfig>
            </section>
            {shouldRenderRangeHeatmap && (
              <section className="relative">
                {/* TODO: Move image into componenet instead using children 👈 blocked by weird heatmap rendering. */}
                <HeatmapRange
                  isDebug={true}
                  organizationId={organizationId}
                  spaceId={spaceId}
                  daterangeBegin={renderDaterange[0]}
                  daterangeEnd={renderDaterange[1]}
                  radius={radius}
                  scaling={scaling}
                  image={{ width: imageWidth }}
                  heatmap={{
                    positionX: heatmapEditor.heatmapX,
                    positionY: heatmapEditor.heatmapY,
                    width: heatmapEditor.heatmapWidth,
                  }}
                  debugCallback={setDebugInfo}
                >
                  {/* 👆 We want to have image HTML inside of the component for better code colocation. */}
                  {image?.url ? (
                    <img src={image.url} className="w-full h-auto" />
                  ) : (
                    <div className="image" />
                  )}
                </HeatmapRange>
              </section>
            )}
          </div>
        </Grid.Cell>
        <Grid.Cell className="infoCards grid gap-4 pt-4">
          <StatsCard key="global" spaceId={spaceId} zone={globalZone} />
          <BlockWrapper key="controls">
            <Formik
              initialValues={initialValues}
              validationScheme={validation}
              onSubmit={(
                values: HeatmapConfigValues,
                { setSubmitting }: FormikHelpers<HeatmapConfigValues>,
              ) => {
                // This is serializing the formik values for PUT
                console.log("Values", values);

                let payload: HeatmapConfigPost = {};
                if (values.radius_multiplier_hasval)
                  payload.radius_multiplier = values.radius_multiplier;
                if (values.scaling_exponent_hasval)
                  payload.scaling_exponent = values.scaling_exponent;

                if (values.heatmap_pos_x)
                  payload.heatmap_pos_x = values.heatmap_pos_x;
                if (values.heatmap_pos_y)
                  payload.heatmap_pos_y = values.heatmap_pos_y;
                if (values.heatmap_width)
                  payload.heatmap_width = values.heatmap_width;

                if (values.heatmap_image) {
                  // New image
                  payload.image = values.heatmap_image;
                } else if (image?.identifier) {
                  // Existing image
                  payload.image = null; // We probably don't need this line
                  payload.image_identifier = image.identifier;
                } else {
                  // No existing image, removed existing image
                  payload.image = null; // We probably don't need this line
                }
                // TODO: image deletion

                // TODO: on submit, patch the space
                // for now, at least reload
                RESTService.putForm<HeatmapConfigPost>({
                  url: `Spaces/${spaceId}/HeatmapConfig`,
                  payload,
                  successCallback: () => {
                    console.log("Success 🙌");
                    invalidateSpace({
                      queryClient,
                      spaceId,
                    });
                    // TODO: Mutate (Space.)HeatmapConfig, rather than invalidate
                    window.location.reload();
                  },
                  setSubmitting,
                });
              }}
            >
              {({ handleChange, isSubmitting }) => (
                <Form>
                  <Grid
                    numberOfColumns={{
                      xl: 5,
                    }}
                  >
                    <Grid.Cell columnSpan={{ xl: 1 }}>
                      <Checkbox name="radius_multiplier_hasval" label="Set?" />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Radius multiplier</label>
                        <Field
                          name="radius_multiplier"
                          type="number"
                          step={0.1}
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 1 }}>
                      <Checkbox name="scaling_exponent_hasval" label="Set?" />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Scaling exponent</label>
                        <Field
                          name="scaling_exponent"
                          type="number"
                          step={0.01}
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 1 }}>&nbsp;</Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Image Bg Width</label>
                        <Field
                          name="image_bg_width"
                          disabled
                          value={imageWidth}
                          type="number"
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 1 }}>
                      <Checkbox name="heatmap_pos_x_hasval" label="Set?" />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Heatmap Position X</label>
                        <Field
                          name="heatmap_pos_x"
                          type="number"
                          onChange={(event) => {
                            handleChange(event);
                            setHeatmapKey((prevKey) => prevKey + 1);
                          }}
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 1 }}>
                      <Checkbox name="heatmap_pos_y_hasval" label="Set?" />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Heatmap Position Y</label>
                        <Field
                          name="heatmap_pos_y"
                          type="number"
                          onChange={(event) => {
                            handleChange(event);
                            setHeatmapKey((prevKey) => prevKey + 1);
                          }}
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 1 }}>
                      <Checkbox name="heatmap_width_hasval" label="Set?" />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 4 }}>
                      <StyledTextInput>
                        <label>Heatmap Width</label>
                        <Field
                          name="heatmap_width"
                          type="number"
                          onChange={(event) => {
                            handleChange(event);
                            setHeatmapKey((prevKey) => prevKey + 1);
                          }}
                        />
                      </StyledTextInput>
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 5 }} className="py-4">
                      <CroppedFileUpload
                        name="heatmap_image"
                        image={image}
                        deleteImageEvent={() => {
                          setImage(null);
                        }}
                      />
                    </Grid.Cell>
                    <Grid.Cell columnSpan={{ xl: 5 }}>
                      <Button
                        label="Save"
                        isSubmitting={isSubmitting}
                        submitButton={true}
                      />
                    </Grid.Cell>
                  </Grid>

                  <FormikForwarder
                    // TODO: auto-check the _hasval thingy when the value is changed, or the like
                    setRadius={(radius) => {
                      setRadius(radius);
                    }}
                    setScaling={(scaling) => {
                      setScaling(scaling);
                    }}
                    setHeatmapEditor={(config) => {
                      setHeatmapEditor(config);
                    }}
                  />
                </Form>
              )}
            </Formik>
          </BlockWrapper>

          <DebugInfoCard info={debugInfo} />
          {heatmapZones &&
            heatmapZones.map((zone) => (
              <StatsCard key={zone.identifier} spaceId={spaceId} zone={zone} />
            ))}

          <BlockWrapper>
            <h3>Range-based heatmap</h3>
            <div>
              <label htmlFor="daterangeBegin">Start</label>
              <input
                id="daterangeBegin"
                type="date"
                onChange={(ev) => setDaterangeBegin(ev.target.value)}
                value={daterangeBegin}
              />
            </div>
            <div>
              <label htmlFor="daterangeEnd">Stop</label>
              <input
                id="daterangeEnd"
                type="date"
                onChange={(ev) => setDaterangeEnd(ev.target.value)}
                value={daterangeEnd}
              />
            </div>
            <Button
              label="Render"
              onClick={() => {
                setRenderDaterange([daterangeBegin, daterangeEnd]);
              }}
            />
            {shouldRenderRangeHeatmap
              ? `Rendering from ${renderDaterange[0]} to ${renderDaterange[1]}`
              : ""}
          </BlockWrapper>
        </Grid.Cell>
      </Grid>
    </StyledAreaHeatmapTest>
  );
};

const StyledAreaHeatmapTest = styled.div``;

export default AreaHeatmapTest;
