import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Image } from "../../../api/v1/image.js";
import { OpeningHour } from "../../../api/v1/openinghour.js";
import { useSpace } from "../../../hooks/api.js";
import Button from "../../../components/UI/Button/Button.js";
import CroppedFileUpload from "../../../components/UI/CroppedFileUpload/CroppedFileUpload.js";
import Dropdown from "../../../components/UI/Dropdown/Dropdown.js";
import TextAreaInput from "../../../components/UI/TextAreaInput/TextAreaInput.js";
import TextInput from "../../../components/UI/TextInput/TextInput.js";
import StyledTextInput from "../../../components/UI/TextInput/TextInput.styles.js";
import FormColumns, {
  FormWrapperLeft,
  FormWrapperRight,
} from "../../../components/atoms/FormColumns/FormColumns.js";
import StyledFormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.styles.js";
import InfoBlock from "../../../components/atoms/InfoBlock/InfoBlock.js";
import StyledInfoBlock from "../../../components/atoms/InfoBlock/InfoBlock.styles.js";
import ValueBlock from "../../../components/atoms/ValueBlock/ValueBlock.js";
import { DAYS_FULL, DAYS_SHORT, LOCATION_TYPES } from "../../../constants.js";
import RESTService from "../../../services/RESTService.js";
import { Col, Row } from "../../../styles/Grid.js";
import { defaultInputLabel } from "../../../styles/cssInput.js";
import { locationData, SpacePostValues, SpaceValues } from "../locations.js";
import { validation } from "./schema.js";

const LocationsFormPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [initVals, setInitVals] = useState<SpaceValues>(null);
  const [image, setImage] = useState<Image>(null);
  const [error, setError] = useState<string>(null);

  const { data: space } = useSpace({
    spaceId: id,
    enabled: !!id,
  });
  useEffect(() => {
    if (id && space) {
      setInitVals({
        ...space,
        location_identifier: space.location?.identifier,
      });
      setImage(space.image);
    }
  }, [space]);

  const locationTypes = LOCATION_TYPES.filter((item) => item.key > 0).map(
    (item) => ({
      key: item.value,
      label: t(`locations.types.${item.value.toLowerCase()}`),
    }),
  );

  if (id && initVals == null) {
    return <></>;
  }

  const addImageValuesToSubmit = (
    submitValues: SpacePostValues,
    imageString,
    imageId,
  ): SpacePostValues => {
    submitValues = { ...submitValues, image: null };

    if (imageString) {
      submitValues = {
        ...submitValues,
        image: imageString,
      };
    } else if (imageId) {
      submitValues = {
        ...submitValues,
        image_identifier: imageId,
      };
    }

    return submitValues;
  };

  const addOpeningClosingTimes = (submitValues: SpaceValues) => {
    const openingHours = [];

    DAYS_SHORT.map((day, index) => {
      const fullday = DAYS_FULL[index];
      let openingHour = "00:00";
      let closingHour = "00:00";

      if (submitValues.opening_hours) {
        openingHour =
          submitValues?.opening_hours[index]?.opening_time ?? "00:00";
        closingHour =
          submitValues?.opening_hours[index]?.closing_time ?? "00:00";
      }

      openingHours.push({
        day_of_week: fullday,
        opening_time: openingHour,
        closing_time: closingHour,
      });
    });

    return openingHours as OpeningHour[];
  };

  return (
    <StyledLocationsFormPage>
      <Formik
        initialValues={initVals}
        validationSchema={validation}
        onSubmit={(
          values: SpaceValues,
          { setSubmitting, setFieldError }: FormikHelpers<SpaceValues>,
        ) => {
          console.log("values", values);

          const initialSubmitValues = {
            //organization_identifier, // TODO: for superadmins
            location_identifier: values.location_identifier,
            // TODO: parentspaceid
            name: values.name,
            type: values.type,
            capacity: values.capacity,
            address: values.address,
            phone_number: values.phone_number,
            description: values.description,
            area_size: values.area_size,
            opening_hours: addOpeningClosingTimes(values),
          };

          const payload = addImageValuesToSubmit(
            initialSubmitValues,
            values.location_image,
            image?.identifier,
          );

          if (!id) {
            void RESTService.postForm<locationData>({
              url: "spaces",
              payload,
              successCallback() /*res: AxiosResponse<locationData>,*/
              : void {
                history("/");
              },
              setSubmitting,
              setError,
              setFieldError,
            });
          } else {
            void RESTService.putForm<locationData>({
              url: `spaces/${id}`,
              payload,
              successCallback() /*res: AxiosResponse<locationData>,*/
              : void {
                history(`/locations/${id}/dashboard`);
              },
              setSubmitting,
              setError,
              setFieldError,
            });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormColumns>
              <FormWrapperLeft>
                <Row>
                  <Col>
                    <h2>
                      {t(`locations.form.header.${id ? "edit" : "create"}`)}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CroppedFileUpload
                      name="location_image"
                      image={image}
                      deleteImageEvent={() => {
                        setImage(null);
                      }}
                    />
                  </Col>
                </Row>
                {/* TODO location dropdown */}
                <Row>
                  <Col>
                    <TextInput name="name" label={t("locations.form.name")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Dropdown
                      name="type"
                      label={t("locations.form.type")}
                      options={locationTypes}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput name="address" label={t("attributes.address")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="phone_number"
                      label={t("attributes.phoneNumber")}
                    />
                  </Col>
                </Row>
              </FormWrapperLeft>
              <FormWrapperRight>
                <Row>
                  <Col>
                    <TextAreaInput
                      name="description"
                      label={t("locations.form.description")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col cols={{ md: 6 / 12 }}>
                    <TextInput
                      name="capacity"
                      label={t("locations.form.capacity")}
                      type="number"
                    />
                  </Col>
                  <Col cols={{ md: 6 / 12 }}>
                    <TextInput
                      name="area_size"
                      label={t("locations.form.areaSize")}
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>{t("locations.form.header.times")}</h3>
                  </Col>
                </Row>
                {/*{t(`dateValues.days.short.${day}`)}*/}
                <Row>
                  <Col cols={{ md: 4 / 12 }}></Col>
                  <Col cols={{ md: 4 / 12 }}>
                    <label className="openCloseTimeLabel">
                      {t("locations.form.openingTime")}
                    </label>
                  </Col>
                  <Col cols={{ md: 4 / 12 }}>
                    <label className="openCloseTimeLabel">
                      {t("locations.form.closingTime")}
                    </label>
                  </Col>
                </Row>
                {DAYS_SHORT.map((day, index) => (
                  <Row key={`location-day-${index}`}>
                    <Col cols={{ md: 4 / 12 }}>
                      <ValueBlock
                        label={t(`dateValues.days.short.${day}`).toUpperCase()}
                        value={null}
                      />
                    </Col>
                    <Col cols={{ md: 4 / 12 }}>
                      <TextInput
                        name={`opening_hours.${index}.opening_time`}
                        type="time"
                        smallHeight
                        smallWidth
                      />
                    </Col>
                    <Col cols={{ md: 4 / 12 }}>
                      <TextInput
                        name={`opening_hours.${index}.closing_time`}
                        type="time"
                        smallHeight
                        smallWidth
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  <Col>
                    <StyledTextInput>
                      <label>{t("locations.form.countResetTime")}</label>
                      <input type="time" defaultValue="00:00:00" />
                    </StyledTextInput>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      label={t("button.submit")}
                      isSubmitting={isSubmitting}
                      submitButton={true}
                    />
                  </Col>
                </Row>
              </FormWrapperRight>
            </FormColumns>
          </Form>
        )}
      </Formik>
      {error && <InfoBlock type="error" body={error} />}
    </StyledLocationsFormPage>
  );
};

const StyledLocationsFormPage = styled.div`
  //padding-bottom: 10px;
  // ${StyledInfoBlock} {
  //   width: 500px;
  //   margin: 30px auto 50px;
  // }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .openCloseTimeLabel {
    ${defaultInputLabel};

    padding-left: 5px;
  }
  //
  // ${Col} {
  //   &:first-child {
  //     ${StyledFormWrapper} {
  //       float: right;
  //     }
  //   }
  //
  //   &:last-child {
  //     ${StyledFormWrapper} {
  //       float: left;
  //       margin-left: 20px;
  //     }
  //   }
  // }
`;

export default LocationsFormPage;
