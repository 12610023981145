import React from "react";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledHeader from "./Header.styles.js";

type HeaderProps = {
  title: string;
  titleClassName?: string;
  children?: React.ReactNode;
};

const Header = ({ title, children, titleClassName }: HeaderProps) => (
  <StyledHeader>
    <BlockWrapper>
      <h2 className={titleClassName}>{title}</h2>

      {children}
    </BlockWrapper>
  </StyledHeader>
);

export default Header;
