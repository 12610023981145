import { useMemo } from "react";
import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { SpaceOptions, ExtendedQueryResult } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnSingle, queryFnMulti } from "./multi.js";
import { PresenceInfo } from "../../api/v1/ReportInfo/index.js";
import { PresenceData } from "../../api/v1/ReportData/index.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
  queryClient: QueryClient;
}

interface SpaceZoneDataOptions<Result> extends SpaceDataOptions<Result> {
  presenceZoneId: string;
}

export function useSpacePresenceZones({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<PresenceInfo[]>): UseQueryResult<PresenceInfo[]> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = ["Spaces", spaceId, "PresenceZones"];
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<PresenceInfo[]>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

export function useSpacePresenceZoneLatestData({
  spaceId,
  presenceZoneId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceZoneDataOptions<PresenceData>): ExtendedQueryResult<PresenceData> {
  const enabled =
    (enabledOption === undefined || enabledOption) &&
    !!spaceId &&
    !!presenceZoneId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "PresenceZones", presenceZoneId, "LatestData"],
    [spaceId, presenceZoneId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnSingle<PresenceData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpacePresenceZonesLatestData({
  spaceId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceDataOptions<DataMap<PresenceData>>): ExtendedQueryResult<
  DataMap<PresenceData>
> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "PresenceZones", multiPlaceholder, "LatestData"],
    [spaceId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnMulti<PresenceData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}
