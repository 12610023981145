import { Field } from "formik";
import React from "react";
import ErrorElement from "../ErrorElement/ErrorElement.js";
import StyledTextAreaInput from "./TextAreaInput.styles.js";

type TextAreaInputProps = {
  name: string;
  placeholder?: string;
  label?: string;
};

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  name,
  placeholder,
  label,
}: TextAreaInputProps) => (
  <StyledTextAreaInput>
    {label && <label>{label}</label>}
    <Field
      name={name}
      placeholder={placeholder}
      component="textarea"
      rows="4"
    />
    <ErrorElement name={name} />
  </StyledTextAreaInput>
);

export default TextAreaInput;
