import { useMemo } from "react";
import { SpaceData } from "~/api/v1/Space";

type Organization = {
  identifier: string;
  name: string;
};

export function useOrgsFromSpaces(spaces: SpaceData[]): Organization[] {
  return useMemo(
    () =>
      spaces
        .map((space) => space.organization)
        .sort(
          (l, r) =>
            +(l.identifier > r.identifier) - +(l.identifier < r.identifier),
        )
        .reduce(
          (acc: Organization[], org) =>
            acc.length > 0 && acc[acc.length - 1].identifier == org.identifier
              ? acc
              : [
                  ...acc,
                  {
                    identifier: org.identifier,
                    name: org.name,
                  },
                ],
          [],
        ),
    [spaces],
  );
}

export function useFilteredSpaces({
  spaces,
  organizationId,
  superSpaceId,
}: {
  spaces: SpaceData[];
  organizationId?: string;
  superSpaceId: string | null;
}): SpaceData[] {
  return useMemo(
    () =>
      spaces.filter(
        (space) =>
          (organizationId === undefined ||
            organizationId == space.organization.identifier) &&
          ((superSpaceId === null && space.super_space === null) ||
            superSpaceId == space.super_space?.identifier) &&
          // TODO: replace this w/ subspace/superspace
          space.identifier !== "35217e6c-7f83-4062-be4e-5e55f476f597",
      ),
    [spaces, organizationId, superSpaceId],
  );
}
