import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { SpaceOptions, baseQueryFn } from "./base.js";
import { PopularTimesData } from "../../api/v1/ReportData/index.js";

export function usePopularTimesData({
  spaceId,
  enabled: enabledOption,
}: SpaceOptions<PopularTimesData>): UseQueryResult<PopularTimesData> {
  const queryKey = ["Spaces", spaceId, "populartimes"];
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const staleTime = 24 * 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<PopularTimesData>,
    staleTime,
  });
}
