import { Field } from "formik";
import React from "react";
import StyledDropdown from "./Dropdown.styles.js";

type DropdownProps = {
  name: string;
  label?: string;
  options: {
    key: string | number;
    label: string;
  }[];
  value?: string | number;
  onChange?: (ev: React.ChangeEvent<HTMLSelectElement>) => void;
};

const Dropdown: React.FC<DropdownProps> = ({
  name,
  label,
  options,
  onChange,
  value,
}: DropdownProps) => {
  if (options == undefined || options.length == 0) {
    return <></>;
  }
  return (
    <StyledDropdown>
      {label && <label>{label}</label>}
      <Field component="select" name={name} onChange={onChange} value={value}>
        {options?.map((item) => {
          return (
            <option value={item.key} key={`dropdown-${name}-${item.key}`}>
              {item.label}
            </option>
          );
        })}
      </Field>
    </StyledDropdown>
  );
};

export default Dropdown;
