import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Image } from "../../../api/v1/image.js";
import { useMe } from "../../../hooks/api.js";
import Button from "../../../components/UI/Button/Button.js";
import CroppedFileUpload from "../../../components/UI/CroppedFileUpload/CroppedFileUpload.js";
import TextInput from "../../../components/UI/TextInput/TextInput.js";
import FormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.js";
import StyledFormWrapper from "../../../components/atoms/FormWrapper/FormWrapper.styles.js";
import InfoBlock from "../../../components/atoms/InfoBlock/InfoBlock.js";
import mergeDefaultValues from "../../../utils/form-merge.js";
import RESTService from "../../../services/RESTService.js";
import { Col, Row } from "../../../styles/Grid.js";
import { OrganizationPostValues, OrganizationValues } from "../auth.js";
import { defaults, validation } from "./schema.js";

// TODO FIXME this is actually the ORGANIZATION PAGE
const AccountPage: React.FC = () => {
  const { t } = useTranslation();
  const [initVals, setInitVals] = useState<OrganizationValues>();
  const [submitDone, setSubmitDone] = useState<boolean>(false);
  const [image, setImage] = useState<Image>(null);

  const { data: meData } = useMe({});
  useEffect(() => {
    if (meData) {
      setInitVals(mergeDefaultValues(defaults, meData.organization));
      setImage(meData.organization.image);
    }
  }, [meData]);

  if (!initVals) {
    return <></>;
  }

  const addImageValuesToSubmit = (
    submitValues: OrganizationValues,
    imageString,
    imageId,
  ): OrganizationPostValues => {
    let submitPostValues = { ...submitValues } as OrganizationPostValues;

    if (imageString) {
      submitPostValues = {
        ...submitPostValues,
        image: imageString,
      };
    } else if (imageId) {
      submitPostValues = {
        ...submitValues,
        image: null,
        image_identifier: imageId,
      };
    }

    return submitPostValues;
  };

  return (
    <StyledAccountPage>
      <Formik
        enableReinitialize
        initialValues={initVals}
        validationSchema={validation}
        onSubmit={(
          values: OrganizationValues,
          { setSubmitting, setFieldError }: FormikHelpers<OrganizationValues>,
        ) => {
          setSubmitDone(false);

          const url = `Organization/${meData.organization.identifier}`;
          const payload = addImageValuesToSubmit(
            values,
            values.form_image,
            image?.identifier,
          );

          void RESTService.putForm({
            url,
            payload,
            successCallback(res: AxiosResponse): void {
              setSubmitDone(true);
              setTimeout(() => {
                setSubmitDone(false);
              }, 5000);
            },
            setFieldError,
            setSubmitting,
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row>
              <Col cols={{ xs: 6 / 12 }}>
                <FormWrapper>
                  <Row>
                    <Col>
                      <h2>{t("account.organization.header")}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CroppedFileUpload
                        name="form_image"
                        label={t("attributes.companyLogo")}
                        image={image}
                        deleteImageEvent={() => {
                          setImage(null);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name="name"
                        label={t("attributes.companyName")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name="c_o_c_number"
                        label={t("attributes.companyRegistrationNumber")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col cols={{ xs: 6 / 12 }}>
                      <TextInput
                        name="postal_code"
                        label={t("attributes.postalCode")}
                      />
                    </Col>
                    <Col cols={{ xs: 3 / 12 }}>
                      <TextInput
                        name="house_number"
                        label={t("attributes.houseNumber")}
                      />
                    </Col>
                    <Col cols={{ xs: 3 / 12 }}>
                      <TextInput
                        name="house_number_suffix"
                        label={t("attributes.houseNumberSuffix")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput name="street" label={t("attributes.street")} />
                      <TextInput name="city" label={t("attributes.city")} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name="phone_number"
                        label={t("attributes.phoneNumber")}
                      />
                    </Col>
                  </Row>
                </FormWrapper>
              </Col>
              <Col cols={{ xs: 6 / 12 }}>
                <FormWrapper>
                  <Row>
                    <Col>
                      <h3>{t("account.administration.header")}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name="billing_contact.first_name"
                        label={t("attributes.firstName")}
                      />
                      <TextInput
                        name="billing_contact.last_name"
                        label={t("attributes.lastName")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name="billing_contact.email"
                        label={t("attributes.email")}
                      />
                      <TextInput
                        name="billing_contact.phone"
                        label={t("attributes.phoneNumber")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        label={t("button.submit")}
                        submitButton={true}
                        isSubmitting={isSubmitting}
                      />
                    </Col>
                  </Row>
                  {submitDone && (
                    <Row>
                      <Col>
                        <InfoBlock
                          type="confirm"
                          body={t("account.updateMessage")}
                        />
                      </Col>
                    </Row>
                  )}
                </FormWrapper>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </StyledAccountPage>
  );
};

const StyledAccountPage = styled.div`
  ${Col} {
    &:first-child {
      ${StyledFormWrapper} {
        float: right;
      }
    }

    &:last-child {
      ${StyledFormWrapper} {
        float: left;
        margin-left: 20px;
      }
    }
  }
`;

export default AccountPage;
