import { useMemo } from "react";
import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { SpaceOptions, ExtendedQueryResult } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnSingle, queryFnMulti } from "./multi.js";
import { ClassifiedGateInfo } from "../../api/v1/ReportInfo/index.js";
import { ClassifiedGateData } from "../../api/v1/ReportData/index.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
  queryClient: QueryClient;
}

interface SpaceClassifiedGateDataOptions<Result>
  extends SpaceDataOptions<Result> {
  gateId: string;
}

export function useSpaceClassifiedGates({
  spaceId,
  enabled: enabledOption,
  placeholderData,
}: SpaceOptions<ClassifiedGateInfo[]>): UseQueryResult<ClassifiedGateInfo[]> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = ["Spaces", spaceId, "ClassifiedGates"];
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<ClassifiedGateInfo[]>,
    placeholderData,
    staleTime: staleTimeInfo,
  });
}

export function useSpaceClassifiedGateLatestData({
  spaceId,
  gateId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceClassifiedGateDataOptions<ClassifiedGateData>): ExtendedQueryResult<ClassifiedGateData> {
  const enabled =
    (enabledOption === undefined || enabledOption) && !!spaceId && !!gateId;
  const queryKey = useMemo(
    () => ["Spaces", spaceId, "ClassifiedGates", gateId, "LatestData"],
    [spaceId, gateId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnSingle<ClassifiedGateData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}

export function useSpaceClassifiedGatesLatestData({
  spaceId,
  enabled: enabledOption,
  placeholderData,
  queryClient,
}: SpaceDataOptions<DataMap<ClassifiedGateData>>): ExtendedQueryResult<
  DataMap<ClassifiedGateData>
> {
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const queryKey = useMemo(
    () => [
      "Spaces",
      spaceId,
      "ClassifiedGates",
      multiPlaceholder,
      "LatestData",
    ],
    [spaceId],
  );
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: queryFnMulti<ClassifiedGateData>,
      meta: { queryClient },
      placeholderData,
      staleTime: staleTimeData,
    }),
    queryKey,
  };
}
