import React, { useCallback, useState } from "react";

export type Organization = {
  identifier: string;
  name: string;
};

type OrganizationSelectProps = {
  organizations: Organization[];
  onChange: (identifier: string) => void;
};

export const noOrg: Organization = {
  identifier: "-",
  name: "-",
};

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
  organizations,
  onChange,
}: OrganizationSelectProps) => {
  const [organization, setOrganization] = useState<string>(noOrg.identifier);

  return (
    <select
      name="organization"
      value={organization}
      onChange={(event) => {
        setOrganization(event.target.value);
        onChange(event.target.value);
      }}
    >
      {organizations?.map((org) => (
        <option key={org.identifier} value={org.identifier}>
          {org.name}
        </option>
      ))}
    </select>
  );
};

export default OrganizationSelect;
